import React, { useState } from 'react'
import Register_content from './Register_content'
import Register_images from './Register_images'
import AnimationCricle from '../../../../Images/RegisterImages/AnimationCricle.png'
import bgImage from '../../../../Images/HomePage/register/bg2.png'
import mobile_bgImage from '../../../../Images/HomePage/register/mobileBg.png'
function Register() {

    return (
        <div className="section registerSection">
            <div className="container">
                <div className="registerContent">
                    <Register_images />
                    <Register_content />
                    <div className="animationCircle_image">
                        <img src={AnimationCricle} alt="AnimationCricle" />
                    </div>
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:767px)" srcSet={mobile_bgImage} />
                    <img src={bgImage} alt="Flowers" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default Register