import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
// --images--
import img1 from '../../../../Images/ContactPage/bannerSectionImg.webp'
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
//--bg line--
import linesBg from '../../../../Images/ContactPage/bannerSectionBgLines.svg'



export default function BannerSection() {
    const { textAnimation } = useAnimations()
    return (
        <div className="bannerSection section">
            <div className="container bannerContainer">
                <div className="boxes">
                    <div className="box box1">
                        <div className="content">

                            <motion.h1 variants={textAnimation} initial="offscreen" whileInView="onscreen">Ponte en contacto
                                con nosotros</motion.h1>
                            <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="pera">Déjanos todas tus  preguntas para ayudarte</motion.p>
                        </div>
                    </div>
                    <div className="box img">
                        <LazyLoadImage src={img1} effect='blur' />
                    </div>
                </div>
                {/* --- */}
                <div className="elementsDiv">
                    <img src={downArrow} alt="element" className="frist" />
                    <img src={starElement} alt="element" className="second" />
                    <img src={starElement} alt="element" className="thired" />
                </div>
                {/* --background image-- */}

                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="bgLine" />
                </picture>

            </div>
        </div>
    )
}
