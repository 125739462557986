import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import ReflectHeading from '../../../CommonComponent/Typography/ReflectHeading'
import EastIcon from '@mui/icons-material/East';
import bgImage from '../../../../Images/HomePage/Steps/bg2.png'
import mobileBg from '../../../../Images/HomePage/Steps/mobileBg.png'
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Steps_card from './Steps_card';
// --card images--
import img1 from '../../../../Images/HomePage/Steps/Rectangle 1244.webp'
import img2 from '../../../../Images/HomePage/Steps/Rectangle 1249.webp'
import img3 from '../../../../Images/HomePage/Steps/Rectangle 1251.webp'
import img4 from '../../../../Images/HomePage/Steps/Rectangle 1299.webp'
import { useNavigate } from 'react-router';
// icons
import { FaRegUser } from 'react-icons/fa'
import { BiPencil } from 'react-icons/bi'
import { CiSettings } from 'react-icons/ci'
import { BsGraphUpArrow } from 'react-icons/bs'
import { FiSend } from 'react-icons/fi'


function Steps() {
    const navigate = useNavigate()
    // ---steps card data---
    const stepsCardData = [
        {
            id: 111,
            img: img1,
            text: "Regístrate y dale un nombre a tu tienda.",
            icon: <FaRegUser />
        },
        {
            id: 112,
            img: img2,
            text: "Personaliza tu página escogiendo los colores que más te identifiquen y las categoría de producto que quieres vender."
            ,
            icon: <BiPencil />
        },
        {
            id: 113,
            img: img3,
            text: "Configura tu cuenta y datos de pagos.",
            icon: <CiSettings />
        },
        {
            id: 114,
            img: img4,
            text: "Comparte el link de tu nueva página web.",
            icon: <BsGraphUpArrow />
        },
        {
            id: 115,
            img: img2,
            text: "Y ¡listo! Comienza a ganar desde el 40% en cada orden.",
            icon: <FiSend />
        },
    ]
    return (
        <div className="stepsSection section">
            <div className="container">
                <div className="setpsContent">
                    <div className="heading">
                        <ReflectHeading boldText="PASO" lightText="A PASO" />
                    </div>
                    {/* ---card slider--- */}
                    <div className="stepsSlider_div">
                        <Swiper
                            slidesPerView="auto"
                            spaceBetween={10}
                            breakpoints={{
                                575: {
                                    slidesPerView: 2,
                                    spaceBetween: 10,
                                },
                                900: {
                                    slidesPerView: 4,
                                    spaceBetween: 10,
                                },
                                1200: {
                                    slidesPerView: 5,
                                    spaceBetween: 20,
                                },
                                1600: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },

                            }}
                            className="stepsSlider swiper"
                        >
                            {stepsCardData && stepsCardData.map((e, index) => {
                                return (

                                    <SwiperSlide key={e.id}>
                                        <Steps_card text={e.text} img={e.img} index={index} icon={e.icon} />
                                    </SwiperSlide>
                                )
                            })}


                        </Swiper>
                    </div>
                    {/* ---card slider--- */}
                    <div className='stepsMain_Button'>
                        <MainButton text="CREAR MI TIENDA" startIcon={<EastIcon />} action={() => { navigate("/form") }} />
                    </div>
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobileBg} />
                    <img src={bgImage} alt="steps" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default Steps