import React from 'react'

function useAnimations() {

    // ---page animation---
    const pageVariants = {
        initial: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: {
                delay: 0.2,
                when: "beforeChildren"
            }
        }
    }

    //--images scale animation--
    const imageScale = {
        offscreen: {
            scale: 0.5
        },
        onscreen: {
            scale: 1,
            transition: {
                duration: 0.5
            }
        }
    }

    // --text animation--
    const textAnimation = {
        offscreen: {
            opacity: 0,
            y: 50,
        },
        onscreen: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    }

    // --text animation--
    const videoIndicate = {
        offscreen: {
            x: "100%"
        },
        onscreen: {
            x: 0,
            transition: {
                duration: 0.5
            }
        },
        onclose: {
            x: "-100%",
            transition: {
                duration: 0.5
            }
        }
    }



    return { pageVariants, imageScale, textAnimation, videoIndicate }
}

export default useAnimations