import React from 'react'
import Text from '../../../CommonComponent/Typography/Text'
import person from '../../../../Images/homeSliderPerson.png'
function Categori_card({ name, link, img }) {
    return (
        <div className="categori_card">
            <p className="card_text" >{name}</p>
            <img src={img} alt={name} />
        </div>
    )
}

export default Categori_card