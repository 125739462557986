import React from "react";
import { motion } from "framer-motion";
import ReflectHeading2 from "../../../CommonComponent/Typography/ReflectHeading2";
import useAnimations from "../../../../Auth/useAnimations";
import bgImage from "../../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";
import {} from "../style.css";

export const Index = () => {
	const { textAnimation } = useAnimations();

	return (
		<div>
			<div className="error">
				<ReflectHeading2 boldText="404" />
				<motion.h4 variants={textAnimation} initial="offscreen" whileInView="onscreen">
					Page Not Found
				</motion.h4>
			</div>

			<picture className="sectionBgImage">
				<source media="(max-width:575px)" srcSet={mobile_bgImage} />
				<img src={bgImage} alt="Flowers" />
			</picture>
		</div>
	);
};
