import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Input_Label from "./Input_Label";
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function InputVerifyStore(props) {

    const [storeName, setStoreName] = useState();
	const [storeNameValid, setStoreNameValid] = useState(null);

    const validProductionAdminUrl = function (url = '') {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${ url }`;
		} else {
			return `https://admin.ventadirekta.com${ url }`;
		}
	};

    const verifyStore = async (value) => {
		try {
			const result = await fetch(validProductionAdminUrl('/api/v1/verify-subdomain'), {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					store_name: storeName,
				}),
			});
			const verify_result = await result.json();
			setStoreNameValid(verify_result.data.valid);
			props.setStoreNameValid(verify_result.data.valid);
		} catch (e) {
			console.log(e);
		}
	};

    const handleStoreName = (event) => {
		setStoreName(event.target.value.trim());
		setStoreNameValid(null);
	};
	return (
		<>
			<Input_Label label={props.label} />
			<TextField 
                type={props.type} 
                name={props.name} 
                label={props.labelText} 
                value={props.value} 
                pattern={props.pattern} 
                sx={{ fontSize: "2rem" }} 
                fullWidth 
                placeholder={props.placeholder}
                variant="standard"
                onChange={handleStoreName}
                onBlur={verifyStore}
                required={props.required}
				error={!storeNameValid ? true : false}
				helperText={storeNameValid === null ? '' : !storeNameValid ? "Nombre ya existe" : ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {/* <Button variant="contained" style={{ backgroundColor: storeNameValid === null ? "#000" : storeNameValid ? "#4caf50" : "#ab003c" }} onClick={verifyStore}>
                                {storeNameValid === null ? "Verificar" : storeNameValid ? "Disponible" : "No disponible"}
                            </Button> */}
                            <InputAdornment position="end">
                                {storeNameValid === null ? "" : storeNameValid ? <CheckIcon style={{color: "#4caf50"}}/> : <CloseIcon style={{color: "#ab003c"}}/>}
                            </InputAdornment>
                        </InputAdornment>
                    ),
                }}
            />
		</>
	);
}

export default InputVerifyStore;
