import React from 'react'
import bg from '../../../../Images/homeSliderBackground.jpg'
import person from '../../../../Images/HomePage/HeroSection/FQ102M-copy.png'
import person_bg from '../../../../Images/HomePage/HeroSection/FQ102M-2.webp'
import useAnimations from '../../../../Auth/useAnimations'
import bgImage from '../../../../Images/HomePage/HeroSection/bg2.png'
import mobile_bgImage from '../../../../Images/HomePage/HeroSection/mobileBg.png'
import { motion } from 'framer-motion'
function HeroSlider_card({ active }) {
    const { imageScale } = useAnimations()
    return (
        <div className={`heroSlider_card  ${active ? `Hero_animation` : ""}`}>

            {/* boxes */}
            <div className="heroBoxes">
                <div className="fristContent">
                    {/* <motion.img variants={imageScale} initial="offscreen" whileInView="onscreen" src={person} className="personImage" /> */}
                    <img src={person} className="personImage" />
                    <div className="text">
                        <span className="bigText">
                            CREA
                        </span>
                        <span className="smallText">
                            {/* TU PAGINA WEB EN 5 MIU */}
                            TU PAGINA WEB EN 5 MIN
                        </span>
                    </div>
                </div>
                <div className="secondContent">
                    <div className="imageCard">
                        <img src={person_bg} />
                        <p className="text">
                            <span>CONVIÉRTETE EN EMBAJADOR DE LAS </span>
                            <span>MEJORES FAJAS Y JEANS COLOMBIANOS</span>
                        </p>
                        <div className="overFlow_text">
                            <span>GANA DESDE EL 40%</span>
                            <span>POR CADA PRODUCTO.</span>
                        </div>
                    </div>
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobile_bgImage} />
                    <img src={bgImage} alt="Flowers" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default HeroSlider_card