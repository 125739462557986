import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Input_Label from "./Input_Label";
import InputAdornment from "@mui/material/InputAdornment";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function InputVerifyOrder(props) {

    const [orderNumber, setOrderNumber] = useState();
	const [orderValid, setOrderValid] = useState(null);

    const validProductionAdminUrl = function (url = '') {
		if (process.env.REACT_APP_NODE_ENV === "TEST") {
			return `http://localhost:3030${ url }`;
		} else {
			return `https://admin.ventadirekta.com${ url }`;
		}
	};

    const verifyOrder = async (value) => {
		try {
			const result = await fetch(validProductionAdminUrl('/api/v1/verify-order'), {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					order_number: orderNumber,
				}),
			});
			const verify_result = await result.json();
            console.log(verify_result)
			setOrderValid(verify_result.data.body != null ? true : false);
			props.setOrderValid(verify_result.data.body != null ? true : false);
		} catch (e) {
			console.log(e);
		}
	};

    const handleOrder = (event) => {
		setOrderNumber(event.target.value.trim());
		setOrderValid(null);
	};
	return (
		<>
			<Input_Label label={props.label} />
			<TextField 
                type={props.type} 
                name={props.name} 
                label={props.labelText} 
                value={props.value} 
                pattern={props.pattern} 
                sx={{ fontSize: "2rem" }} 
                fullWidth 
                placeholder={props.placeholder}
                variant="standard"
                onChange={handleOrder}
                onBlur={verifyOrder}
                required={props.required}
				error={!orderValid ? true : false}
				helperText={orderValid === null ? '' : !orderValid ? "La orden no existe" : ''}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {orderValid === null ? "" : orderValid ? <CheckIcon style={{color: "#4caf50"}}/> : <CloseIcon style={{color: "#ab003c"}}/>}
                        </InputAdornment>
                    ),
                }}
            />
		</>
	);
}

export default InputVerifyOrder;
