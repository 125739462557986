import React from 'react'
function Benefits_card({ img, previewImage, text, index }) {
    return (
        <div className="benefits_card active">
            <div className="cardImg">
                <img src={img} alt="#" />
            </div>
            <div className="preview_image">
                <img src={previewImage} alt="#" />
            </div>
            <div className="content">
                <p className="number">0{index + 1}.</p>
                <p className="text">
                    {text}
                </p>
            </div>
        </div>
    )
}

export default Benefits_card