import { Box, InputAdornment } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Input_Label from "./Input_Label";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";

// ---
import { MuiTelInput } from "mui-tel-input";

function PhoneNumber_input({ label, placeholder, action, icon, popoverText, name, ...props }) {
	const [anchorEl, setAnchorEl] = React.useState(null);

	// --number value--
	const [numberValue, setNumberValue] = useState();
	const [numberError, setNumberError] = useState(false);
	const handleChange = (newPhone) => {
		setNumberValue(newPhone);
		setNumberError(!isValidPhoneNumber(newPhone) ? true : false);
		props.callBackIsValid(isValidPhoneNumber(newPhone));
	};
	// /---
	const popoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const popoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={popoverClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}>
				<Typography sx={{ p: 2 }}>{popoverText}</Typography>
			</Popover>
			<Input_Label label={props.Inlabel ? "" : label} />
			<MuiTelInput
				label={props.Inlabel ? props.Inlabel : ""}
				error={numberError}
				name={name}
				defaultCountry="US"
				value={numberValue}
				placeholder={placeholder}
				onChange={handleChange}
				variant="standard"
				fullWidth
				required={props.required}
				InputProps={{
					required: props.required,
					endAdornment: (
						<InputAdornment position="end" sx={{ color: "var(--one-color)", cursor: "pointer" }} aria-describedby={id} onClick={popoverOpen}>
							{icon}
						</InputAdornment>
					),
				}}
			/>
		</>
	);
}
export default PhoneNumber_input;
