import React, { Suspense, useEffect } from "react";
import { Route, Routes, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";

import { Home as HomeLayout } from "../Layouts/Home";
import Home from "../Component/Pages/Home/Home";
import Forms from "../Component/Pages/Forms/Forms";
import FormReturns from "../Component/Pages/Forms/form-returns";
import { Index as PrivacyPolitics } from "../Component/Pages/Static/privacyPolitics";
import { Index as TermsConditions} from "../Component/Pages/Static/termsConditions";
import { Index as ReturnsChanges} from "../Component/Pages/Static/returnsChanges";
import { Index as Faqs} from "../Component/Pages/Static/faqs";
import { Index as TYFR } from "../Component/Pages/ThankForRegister/index";
import { Index as NotFound } from "../Component/Pages/Errors/404/index";
import About from "../Component/Pages/About/About";
import Academy from "../Component/Pages/Academy/Academy";
import Blogs from "../Component/Pages/Blogs/Blogs";
import {Index as Blog} from "../Component/Pages/Blogs/Blog";
import Contact from "../Component/Pages/Contact/Contact";
import Errorpage from "../Component/Pages/Errorpage";
import Testimonials from '../Component/Pages/Testimoniales/Testimonials'
import WantToSell from "../Component/Pages/WantToSell/WantToSell";

export default function StoresRouter() {
	let [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();

	return (
		<Suspense
			fallback={
				<div className="landing-pre-loader-div">
					<div className="landing-pre-loader">
						<img src="https://ventadirekta.com/assets/images/logo/logo-white.webp" alt="" />
						<LinearProgress style={{ color: "white", background: "black", width: "100%" }} />
					</div>
				</div>
			}>
			<Routes>
				<Route element={<HomeLayout />}>
					<Route path="/" element={<Home />} title="Home" />
					<Route path="/privacy-politics" element={<PrivacyPolitics />} title="Privacy Politics" />
					<Route path="/terms-conditions" element={<TermsConditions />} title="Terms and Conditions" />
					<Route path="/returns-changes" element={<ReturnsChanges />} title="Return and Changes" />
					<Route path="/faqs" element={<Faqs />} title="Faqs" />
					<Route path="/form" element={<Forms />} title="Form" />
					<Route path="/about" element={<About />} title="About" />
					<Route path="/academy" element={<Academy />} title="Academy" />
					<Route path="/blogs" element={<Blogs />} title="Blogs" />
					<Route path="/blogs/:id" element={<Blog />} title="Blogs" />
					<Route path="/contact" element={<Contact />} title="Contact" />
					<Route path="/testimonials" element={<Testimonials />} title="Testimonials" />
					<Route path="/want-to-sell" element={<WantToSell />} title="Want To Sell" />
					<Route path="/form-returns" element={<FormReturns />} title="Form Returns" />
					<Route path="/thank-you-for-register" element={<TYFR />} title="TYFR" />
					<Route path="*" element={<NotFound />} title="404" />
				</Route>
			</Routes>
		</Suspense>
	);
}
