import React from "react";
import TextField from "@mui/material/TextField";
import Input_Label from "./Input_Label";

function TextInputMaxLength(props) {

	
	return (
		<>
			<Input_Label label={props.label} />
			<TextField type={props.type} name={props.name} label={props.labelText} value={props.value} inputProps={{ maxLength: props.maxlength }} pattern={props.pattern} sx={{ fontSize: "2rem" }} fullWidth placeholder={props.placeholder} variant="standard" onChange={props.action} required={props.required} />
		</>
	);
}

export default TextInputMaxLength;
