import React from 'react'
import Text from '../../../CommonComponent/Typography/Text'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import img from '../../../../Images/demo_image.png'

function Steps_card({ img, text, index, icon }) {
    return (
        <div className="steps_card">
            <div className="img">
                <img src={img} alt="#" />
            </div>
            <div className="numDiv">
                <p>0{index + 1}.</p>
                <span>{icon}</span>
            </div>
            <p className='cardText'>{text}</p>
        </div>
    )
}

export default Steps_card