import React, { useState, useEffect } from "react";

import Header from "../Component/CommonComponent/Header/Header";
import Footer from "../Component/CommonComponent/Footer/Footer";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Whatsapp_btn from "../Component/CommonComponent/Floating_buttons/Whatsapp_btn";
import GoTop_btn from "../Component/CommonComponent/Floating_buttons/GoTop_btn";

export const Home = () => {
    
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    return(
        <>
            <Header isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen}/>

            <Whatsapp_btn />
            <GoTop_btn />

            <Outlet />

            <Footer />
            <ToastContainer />
        </>
    )
}
