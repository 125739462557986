import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import Text from '../../../CommonComponent/Typography/Text'
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router';

function MoreInfoForm_firstDiv() {
    const navigate = useNavigate()
    return (
        <div className="firstDiv">
            <Text classText="heading" boldText="SI ESTÁS LISTO" text="PARA EMPEZAR" />
            <Text classText="pera" text="completa el formulario de creación de tienda y estarás a un paso de comenzar a generar ingresos extra." />
            <MainButton text="CREAR TIENDA" startIcon={<EastIcon />} action={() => { navigate("/form") }} />
        </div>
    )
}

export default MoreInfoForm_firstDiv