import React, { useState } from 'react'
import TestimonialContent_Box from './TestimonialContent_Box'
import Testimonial_AvtarBox from './Testimonial_AvtarBox'
import Testimonial_box from './Testimonial_box'
import bgImage from '../../../../Images/HomePage/testimonial/bg.webp'
import mobileBg from '../../../../Images/HomePage/testimonial/mobileBg.png'
// --
import userBg1 from '../../../../Images/Testimonial_images/userBg1.png'
import userBg2 from '../../../../Images/Testimonial_images/userBg2.png'
import userBg3 from '../../../../Images/Testimonial_images/userBg3.png'

import profileImage from '../../../../Assets/images/resources/avatar.webp'
import profileGabriella from '../../../../Assets/images/resources/gabriella.webp'
import profileIris from '../../../../Assets/images/resources/iris.webp'
import profileIrisSanchez from '../../../../Assets/images/resources/iris_sanchez.webp'

function Testimonial() {

    // ---testimonia active function---
    const [activeClass, setActiveClass] = useState("testi2")
    const testimonialActive_fun = (e) => {
        setActiveClass(e)
    }

    // --testiminial array--
    const testiMonial_arrayData = [
        {
            id: "testi1",
            name: "Gabriella",
            text: "“Soy Gabriella, y trabajo con Ventadirekta desde junio 2021. Dichosas somos que podemos trabajar con una compañía que nos apoya tanto en el crecimiento de nuestro negocio, y la flexibilidad de trabajar desde la casa con nuestras familias. Gracias Ventadirekta.”",
            img: profileGabriella,
            bgImg: userBg3,
        },
        {
            id: "testi2",
            name: "Iris Altagracia",
            text: "“Mi nombre es Iris Altagracia, empecé a trabajar con Ventadirekta y estoy super agradecida por que perdí mi empleo por la pandemia y esta compañía me ha dado la solución para trabajar desde casa sin descuidar  a los míos, haciendo algo que me gusta que es las ventas y la belleza, además de eso, con mercancías de muy buena calidad, estoy super agradecida.”",
            img: profileIris,
            bgImg: userBg1,
        },
        {
            id: "testi3",
            name: "Iris Sanchez",
            text: "“Hola! Mi nombre es Iris Sanchez, soy del estado de Arizona... Tengo como 1 mes aproximadamente en Ventadirekta. Mi experiencia ha sido muy fascinante ya que tengo mucho tiempo libre para estar con mi familia, ya que tengo mi propia tienda virtual donde solo tengo que compartir con mis cielntas y listo! No tengo que preocuparme por el envío ni nada de eso, ya que la compañía se encarga de eso y es espectacular... Así que si estás intentando tener tu tienda virtual y vender las mejores marcas  de fajas en el mercado, te invito a que te unas a esta gran familia donde te brindan todo el apoyo y capacitación que necesitas.”",
            img: profileIrisSanchez,
            bgImg: userBg1,
        },

    ]
    return (
        <div className="section testmonialSection">
            <div className="container">
                <div className="testimonialContent">
                    <TestimonialContent_Box />
                    <Testimonial_box activeClass={activeClass} testiMonial_arrayData={testiMonial_arrayData} testimonialActive_fun={testimonialActive_fun} />
                    <Testimonial_AvtarBox />
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobileBg} />
                    <img src={bgImage} alt="steps" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default Testimonial