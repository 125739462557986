import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import { styled } from "@mui/material/styles";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import MainButton from "../../../CommonComponent/Button/MainButton";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router";

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					{children}
				</Box>
			)}
		</div>
	);
}
function a11yProps(index) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}
TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};
const AntTabs = styled(Tabs)({
	margin: "1rem 7px",
	"& .MuiTabs-indicator": {
		backgroundColor: "var(--secondary-color)",
		position: "absolute",
		top: "0",
		left: "0",
		height: "100%",
		color: "var(--white-color)",
	},
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	fontSize: "1.2rem",
	textAlign: "left",
	wordBreak: "keep-all",
	width: "180px",
	"&:hover": {
		color: "var(--white-color)",
		fontWeight: "500",
		backgroundColor: "var(--secondary-color)",
		opacity: "0.7",
	},
	"&.Mui-selected": {
		color: "var(--white-color)",
		fontWeight: "500",
		zIndex: "2",
	},
}));


export default function ThiredSection() {

    const navigate = useNavigate();
	const [value, setValue] = React.useState(0);
	const theme = useTheme();
	const tabChange = (event, newValue) => {
		setValue(newValue);
	};

    return (
        <div className="container thiredContainer">
            <div className="howToTabs">
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 0 }}>
                        <AntTabs value={value} onChange={tabChange} variant={window.innerWidth > 800 ? "fullWidth" : "scrollable"}>
                            <AntTab label="COMO CREAR TU TIENDA" {...a11yProps(0)} />
                            <AntTab label="COMO LUCE TU TIENDA" {...a11yProps(1)} />
                            <AntTab label="COMO CONFIGURAR TU TIENDA" {...a11yProps(2)} />
                            <AntTab label="APRENDE CON NOSOTROS" {...a11yProps(3)} />
                            <AntTab label="GESTIONA PEDIDOS Y CLIENTES" {...a11yProps(4)} />
                        </AntTabs>
                    </Box>
                    <Box className="tabs_video_box p-0">
                        <TabPanel value={value} index={0}>
                            <div className="videoDiv">
                                <ReactPlayer
                                    className="react-player"
                                    style={{ width: "100%" }}
                                    // light={<img src="https://img.freepik.com/free-vector/1920-s-fashion-fashion-pattern-vector-feminine-background-remix-from-artworks-by-george-barbier_53876-140018.jpg?w=1380&t=st=1678180964~exp=1678181564~hmac=c28267a946f62582a21f08e342831a7a67b9dfc9e7faa9089de9f4bcefaccc7d" alt="Thumbnail" />}
                                    url="https://www.youtube.com/watch?v=gHD3BIY2hEM"
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <div className="videoDiv">
                                <ReactPlayer style={{ width: "100%" }} 
                                // light={<img src="https://img.freepik.com/premium-photo/smiling-cute-teenager-boy-19-20-year-old-wear-glasses-hood-outdoor-closeup_260913-3959.jpg?w=1380" alt="Thumbnail" />} 
                                url="https://www.youtube.com/watch?v=uJt_-A5_uko" />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <div className="videoDiv">
                                <ReactPlayer
                                    style={{ width: "100%" }}
                                    // light={<img src="https://img.freepik.com/free-vector/1920-s-fashion-fashion-pattern-vector-feminine-background-remix-from-artworks-by-george-barbier_53876-140018.jpg?w=1380&t=st=1678180964~exp=1678181564~hmac=c28267a946f62582a21f08e342831a7a67b9dfc9e7faa9089de9f4bcefaccc7d" alt="Thumbnail" />}
                                    url="https://www.youtube.com/watch?v=Zx_-6JqBj1Y"
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <div className="videoDiv">
                                <ReactPlayer
                                    style={{ width: "100%" }}
                                    // light={<img src="https://img.freepik.com/free-vector/1920-s-fashion-fashion-pattern-vector-feminine-background-remix-from-artworks-by-george-barbier_53876-140018.jpg?w=1380&t=st=1678180964~exp=1678181564~hmac=c28267a946f62582a21f08e342831a7a67b9dfc9e7faa9089de9f4bcefaccc7d" alt="Thumbnail" />}
                                    url="https://www.youtube.com/watch?v=BVHmtGr5Hes"
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                            <div className="videoDiv">
                                <ReactPlayer style={{ width: "100%" }} 
                                // light={<img src="https://img.freepik.com/premium-photo/smiling-cute-teenager-boy-19-20-year-old-wear-glasses-hood-outdoor-closeup_260913-3959.jpg?w=1380" alt="Thumbnail" />} 
                                url="https://www.youtube.com/watch?v=TmVP08JqD0E" />
                            </div>
                        </TabPanel>
                        {/* --bg cricle boxes-- */}
                        <span className="cricle_one"></span>
                        <span className="cricle_two"></span>
                        {/* --//bg cricle boxes-- */}
                    </Box>
                    {/* <div className="tabMain_Button">
                        <a href="#MoreInfoForm_Section_id">
                            <MainButton text="QUIERO EMPEZAR" startIcon={<EastIcon />} />
                        </a>
                    </div> */}
                </Box>
            </div>
        </div>
    )
}
