import { Typography } from '@mui/material'
import React from 'react'
import useAnimations from '../../../Auth/useAnimations'
import { motion } from 'framer-motion'

function ReflectHeading({ boldText, lightText }) {
    const { textAnimation } = useAnimations()
    return (
        <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen">
            <Typography variant="subtitle2" component="h2" sx={{ position: "relative", fontSize: { xs: "1.5rem", sm: "2rem", md: "3rem", }, textAlign: "center", fontFamily: "var(--heading-font-family)" }}>
                <Typography variant="subtitle1" component="span" sx={{ position: "absolute", top: "-20%", left: "50%", transform: "translateX(-50%)", opacity: "0.05", fontSize: { xs: "2.2rem", sm: "3.5rem", md: "4.5rem" }, whiteSpace: "nowrap", fontFamily: "var(--heading-font-family)" }}>
                    <span style={{ fontWeight: "700" }}>{boldText}</span> <span style={{ fontWeight: "400" }}> {lightText} </span>
                </Typography>
                <span style={{ fontWeight: "600" }}>{boldText}</span> <span style={{ fontWeight: "400" }}> {lightText} </span>
            </Typography>
        </motion.div>
    )
}

export default ReflectHeading