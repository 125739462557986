import { Typography } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion'
import useAnimations from '../../../Auth/useAnimations'

function Heading({ text }) {
    const { textAnimation } = useAnimations()
    return (
        <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen">
            <Typography variant='subtitle2' component="h2" sx={{ color: "var(--lightBlack-color)", fontSize: "2.5rem", fontWeight: "600", fontFamily: "var(--heading-font-family)" }}>{text}</Typography>
        </motion.div>
    )
}

export default Heading