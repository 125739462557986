import React from 'react'
import ReflectHeading from '../../../CommonComponent/Typography/ReflectHeading'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
//--images--
import bgLines from '../../../../Images/AboutPage/secondSectionBgLines.svg'
//--elements--
import downArrow from '../../../../Images/elements/downArrow2.svg'
import blockes from '../../../../Images/elements/blockes.svg'
export default function SecondSection() {
    const { textAnimation } = useAnimations()

    return (
        <div className="section secondSection">
            <div className="container secondContainer">
                <div className="heading">
                    <ReflectHeading boldText="¡EVOLUCIONAMOS!" />
                </div>
                <div className="text">
                    <motion.span variants={textAnimation} initial="offscreen" whileInView="onscreen">
                        LLEVAMOS AL MUNDO DIGITAL
                        La TRADICIONAL VENTA POR CATÁLOGO
                    </motion.span>
                    <motion.span variants={textAnimation} initial="offscreen" whileInView="onscreen">
                        CON 10 AÑOS DE EXPERIENCIA EN EL MERCADO
                        SOMOS UNA OPURTUNIDAD DE NEGOCIO
                    </motion.span>
                </div>
                {/* -- */}
                <div className="elementsDiv">

                    <img src={downArrow} alt="element" className="first" />
                    <img src={blockes} alt="element" className="second" />

                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={bgLines} alt="bglines" />
                </picture>
            </div>
        </div>
    )
}
