import React from 'react'
import MoreInfoForm_firstDiv from './MoreInfoForm_firstDiv'
import MoreInfoForm_secondDiv from './MoreInfoForm_secondDiv'
import bgImage from '../../../../Images/HomePage/Form/bg.png'
import mobile_bgImage from '../../../../Images/HomePage/Form/mobileBg.png'

function MoreInfoForm() {
    return (

        <div className="section MoreInfoFormSection" id='MoreInfoForm_Section_id'>
            <div className="container">
                <div className="MoreInfoForm_content">
                    <MoreInfoForm_firstDiv />
                    <MoreInfoForm_secondDiv />
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobile_bgImage} />
                    <img src={bgImage} alt="Flowers" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default MoreInfoForm