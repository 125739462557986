import React from 'react'
import CricleImage_bg from '../../../../Images/Testimonial_images/CricleImage_bg.png'
import MobileImage_bg from '../../../../Images/Testimonial_images/MobileImage_bg.png'
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import { Link } from "react-router-dom";

function Testimonial_box({ activeClass, testiMonial_arrayData, testimonialActive_fun }) {

    //--get fun/and arraydata form main page and add card by loop + when user click on any profile img so function chack id and match it or add active class for show--

    return (
        <div className="Testimonail_box">
            <div className="criculeImage">
                <img src={CricleImage_bg} alt="#" />
            </div>
            {/* --box-- */}
            <div className="testimonialBox">
                <div className="phoneBox">
                    <img src={MobileImage_bg} alt="#" />
                    <Link to="/testimonials" className="PlayButton">
                        <PlayCircleOutlineOutlinedIcon sx={{ height: "35px", width: "35px" }} />
                        <span>VER TESTIMONIOS</span>
                    </Link>
                </div>
                <div className="userBox">
                    {testiMonial_arrayData.map((e) => {
                        return (
                            <div className="imagesBox" key={e.id} onClick={() => testimonialActive_fun(e.id)}>
                                <img src={e.bgImg} className='bgColor' alt="#" />
                                <img src={e.img} className='personImage' alt="#" />
                            </div>
                        )
                    })}

                </div>

            </div>
            {/* --testimonial content-- */}
            <div className="userBox_review">
                {testiMonial_arrayData.map((e, index) => {
                    {/* --only 2 card show-- */ }
                    if (index < 3) {
                        return (
                            <div key={index} className={`user_review ${activeClass == e.id && "active"}`}>
                                <div className="userDetail">
                                    <img src={e.img} alt={e.name} className="profile" />
                                    <h4>{e.name}</h4>
                                </div>
                                <p className="review">{e.text}</p>
                            </div>
                        )
                    }

                })}
            </div>

        </div>
    )
}

export default Testimonial_box