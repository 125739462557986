import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
//images
import bigImg1 from '../../../../Images/WantToSellPage/BannerSectionImg1.webp'
import smallImg1 from '../../../../Images/WantToSellPage/BannerSectionImg2.webp'
import smallImg2 from '../../../../Images/WantToSellPage/BannerSectionImg3.webp'
import bigImg1Mobile from '../../../../Images/WantToSellPage/BannerSectionImg1Mobile.webp'
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'

// --bg lines--
import linesBg from '../../../../Images/AboutPage/heroSectionLines.svg'

export default function BannerSection() {
    return (
        <div className="section bannerSection">
            <div className="container bannerContainer">
                <div className="boxes">
                    <div className="box box1">
                        <LazyLoadImage src={bigImg1} className='desktopImg' />
                        <LazyLoadImage src={bigImg1Mobile} className='mobileImg' />
                    </div>
                    <div className="box box2">
                        <span></span>
                        <div className="img1">

                            <LazyLoadImage src={smallImg1} />
                        </div>
                        <div className="content">
                            <p> <span> nosotros nos encargamos
                                del resto </span> ( despacho,
                                servicio al cliente, cobros,
                                promociones y demás)</p>
                        </div>
                        <div className="img2">
                            <LazyLoadImage src={smallImg2} />
                        </div>

                    </div>
                </div>
                {/* --- */}
                <div className="elementsDiv">
                    <img src={pentagon} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                    <img src={starElement} alt="element" className="thired" />
                </div>

                {/* --background image-- */}
                {/* --it image same like about banner section so i get it from there-- */}
                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="bgLine" />
                </picture>


            </div>
        </div>
    )
}
