import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import Text from '../../../CommonComponent/Typography/Text'
import EastIcon from '@mui/icons-material/East';
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations';
// --img--
import line from '../../../../Images/HomePage/HowToWork/Line.png'
import { useNavigate } from 'react-router';

function HowTextContent() {
    const navigate = useNavigate()
    const { textAnimation } = useAnimations()
    return (
        <div className="howTextContent">
            <motion.h1 variants={textAnimation} initial="offscreen" whileInView="onscreen"><span> ¿CÓMO </span> FUNCIONA?</motion.h1>
            <motion.h4 variants={textAnimation} initial="offscreen" whileInView="onscreen"> <span> EN 5 MINUTOS PODRÁS </span> CREAR TU TIENDA</motion.h4>
            <Text text="Completa el formulario con todos tus datos, el nombre que escogiste para tu tienda, los colores que van mejor con tu estilo y las categorías que deseas vender y listo. Verificamos tus datos y tendrás lista tu página web para compartir con tus conocidos y empezar a vender." />
            <MainButton text="CREAR MI TIENDA" startIcon={<EastIcon />} action={() => { navigate("/form") }} />
            <img src={line} alt="#" className="lineImage" />
        </div>
    )
}

export default HowTextContent