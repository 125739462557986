import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Input_Label from "./Input_Label";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Select_input({ label, placeholder, action, name, options, optionLabel = true, optionTitle, width = "100%", ...props }) {
  const defaultProps = {
    options: options,
    getOptionLabel: (option) => optionLabel ? option[optionTitle] : option,
  };

  return (
    <FormControl variant="standard" sx={{ width: width }}>
      <Input_Label label={label} />
      <Autocomplete
        {...defaultProps}
        popupIcon={<ExpandMoreIcon sx={{ color: "var(--one-color)" }} />}
        onChange={(event, newValue) => {
          action(newValue.name);
        }}

        renderInput={(params) => (
          <TextField
            {...params}
            required={props.required}
            name={name}
            placeholder={placeholder}
            variant="standard"
          />
        )}
      />
    </FormControl>
  );
}
