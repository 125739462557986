import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import Text from '../../../CommonComponent/Typography/Text'
import EastIcon from '@mui/icons-material/East';

function TestimonialContent_Box() {
    return (
        <div className="TestimonailContent_Box">
            <p className='subHeading'>TESTIMONIALES</p>
            <h2 className='heading'>  TESTIMONIO DE   </h2>
            <p className="second_heading">NUESTROS EMBAJADORES</p>
            <p className='pera' >Conoce a quienes hoy trabajan con nosotros y sus experiencias, seguro te identificas con alguno de ellos, también son fuente de inspiración para ti.</p>
            <a href='#MoreInfoForm_Section_id'>
                <MainButton text="QUIERO EMPEZAR" startIcon={<EastIcon />} />
            </a>
        </div>
    )
}

export default TestimonialContent_Box