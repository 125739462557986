import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
// --images--
import img1 from '../../../../Images/AboutPage/thiredSectionImg.webp'
import img2 from '../../../../Images/AboutPage/thiredSectionImg2.webp'
import linesBg from '../../../../Images/AboutPage/thiredSectionBgLine.svg'
import linesBgMobile from '../../../../Images/AboutPage/thiredSectionBgLineMobile.svg'

//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'

export default function ThiredSection() {
    const { textAnimation } = useAnimations()
    return (
        <div className="section thiredSection">
            <div className="container thiredContainer">
                <div className="content items">
                    <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="item heading"><span> nos Prefieren </span> PORQUE</motion.div>
                    <div className="item fristBox">
                        <div className="box">
                            <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen">SOMOS EXPERTOS EN PRODUCTOS DE
                                COMPRESIÓN, TEXTILES Y COMESTICOS.</motion.p>
                            {/* <Text text="Completa el formulario con todos tus datos, el nombre que escogiste para tu tienda, los colores que van mejor con tu estilo y las categorías que deseas vender y listo. Verificamos tus datos y tendrás lista tu página web para compartir con tus conocidos y empezar a vender." /> */}
                            <LazyLoadImage src={img1} effect='blur' />
                        </div>
                    </div>
                    <div className="item secondBox">
                        <div className="box">
                            <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen">NUESTROS PRODUCTOS
                                SON  100% COLOMBIANOS
                                DE ALTÍSIMA CALIDAD</motion.p>
                            <LazyLoadImage src={img2} effect='blur' />
                        </div>
                    </div>
                </div>

                {/* -- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={pentagon} alt="element" className="second" />
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={linesBgMobile} />
                    <img src={linesBg} alt="lines" />
                </picture>
            </div>
        </div>
    )
}
