import React from 'react'
import HeroSlider from './HeroSection/HeroSlider'
import Categorias from './Categorias/Categorias'

import { motion } from "framer-motion"
import useAnimations from '../../../Auth/useAnimations'
import CompaniesSlider from './CompaniesSlider/CompinesSlider'
import HowToWork from './HowToWork/HowToWork'
import Steps from './Steps/Steps'
import Testimonial from './Testimonial/Testimonial'
import Benefits from './Benefits/Benefits'
import Register from './Register/Register'
import MoreInfoForm from './MoreInfoForm/MoreInfoForm'
function Home() {
    const { pageVariants } = useAnimations()
    return (
        <>
            <motion.div className='homePage' variants={pageVariants} initial="initial" animate="visible">
                <HeroSlider />
                <Categorias />
                <CompaniesSlider />
                <HowToWork />
                <Steps />
                <Testimonial />
                <Benefits />
                <Register />
                <MoreInfoForm />
            </motion.div>
        </>
    )
}

export default Home