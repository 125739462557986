/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import useAnimations from "../../../Auth/useAnimations";
import MainButton from "../../CommonComponent/Button/MainButton";
import { useNavigate } from "react-router";

import EastIcon from "@mui/icons-material/East";
import CircularProgress from "@mui/material/CircularProgress";

import PhoneNumber_input from "../../CommonComponent/Inputs/PhoneNumber_input";
import Select_input from "../../CommonComponent/Inputs/Select_input";
import TextField_with_icon from "../../CommonComponent/Inputs/TextField_with_icon";
import Text_input from "../../CommonComponent/Inputs/Text_input";
import InputVerifyOrder from "../../CommonComponent/Inputs/InputVerifyOrder";
import Heading from "../../CommonComponent/Typography/Heading";
import Text from "../../CommonComponent/Typography/Text";
import bgImage from "../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";

import { toast } from "react-toastify";

import {} from "./style.css";

function FormReturns() {
  const [load, setLoad] = useState(true);

  const [callBackIsValid, setCallBackIsValid] = useState("");
  const [orderValid, setOrderValid] = useState(false);

  let productTypeOptions = [
    {
      name: "Faja",
      value: [
        { name: "¿Por qué compraste la faja?", type: "Select", options: ["Postquirúrgica", "Postparto", "Uso diario", "Evento Especial", "Hacer deporte", "Otro"] },
        { name: "¿Qué tipo de cuerpo eres?", type: "Select", options: ["Rectángulo", "Triángulo", "Reloj de Arena", "Triángulo invertido", "Redondo"] },
        { name: "¿Cuál de las siguientes te describe mejor?", type: "Select", options: ["Afroamericana", "Hispana o Latina", "Asiática", "Americana", "Otra"] },
        { name: "¿Cuál es tu rango de estatura?", type: "Select", options: ["5.25 ft / 1.60 m or less", "5.28 ft - 5.51 ft / 1.61 m - 1.68 m", "5.52 ft / 1.69 m or more"] },
        { name: "¿Cuál crees que es la proporción de tu cuerpo?", type: "Select", options: ["Torso corto y piernas largas", "Torso y piernas proporcionales", "Torso largo y piernas cortas"] },
        { name: "¿Has usado fajas antes?", type: "Select", options: ["Nunca", "Una o dos veces", "La uso a diario", "La uso algunas veces al mes"] },
        { name: "¿Por qué quieres cambiar este producto?", type: "Select", options: ["Muy pequeño", "Muy grande", "No me queda muy bien", "No me gustó una parte específca de la faja", "La faja llegó en malas condiciones", "Otro"] },
        { name: "¿Qué talla compraste?", type: "Select", options: ["2XS", "XS", "S", "M", "L", "XL", "2XL", "3XL", "4XL", "Otra"] },
        { name: "¿Basado en qué escogiste tu talla?", type: "Select", options: ["Tabla de Medidas", "Reviews", "Según la talla que había usado antes en otre faja", "Otra"] },
        { name: "¿Cuáles son las medidas de tu cintura?", type: "Input", placeholder: "Escrible las medidas de tu cintura" },
        { name: "¿Cuáles son las medidas de tu cadera?", type: "Input", placeholder: "Escrible las medidas de tu cadera" },
        { name: "Qué parte te quedó grande?", type: "Select", options: ["Piernas", "Glúteos", "Abdomen", "Cintura", "Espalda", "Brasier", "Hombros", "Otra"] },
        { name: "¿Puedes indicarnos a qué hora te podemos contactar?", type: "Select", options: ["Mañana", "Tarde", "Noche", "Otra"] },
        { name: "Por favor indíquenos como desea que lo contactemos", type: "Select", options: ["Llamada", "Whatsapp o msj", "email"] },
      ],
    },
    {
      name: "Jean",
      value: [
        { name: "¿Por qué quieres cambiar el producto?", type: "Select", options: ["Muy pequeño", "Muy grande", "No me queda muy bien", "No me gustó una parte específca", "Llegó en malas condiciones", "Otro"] },
        { name: "¿Puedes indicarnos a qué hora te podemos contactar?", type: "Select", options: ["Mañana", "Tarde", "Noche", "Otra"] },
        { name: "Por favor indíquenos como desea que lo contactemos", type: "Select", options: ["Llamada", "Whatsapp o msj", "email"] },
      ],
    },
    {
      name: "Otro",
      value: [
        { name: "¿Por qué quieres cambiar el producto?", type: "Select", options: ["Muy pequeño", "Muy grande", "No me queda muy bien", "No me gustó una parte específca", "Llegó en malas condiciones", "Otro"] },
        { name: "¿Puedes indicarnos a qué hora te podemos contactar?", type: "Select", options: ["Mañana", "Tarde", "Noche", "Otra"] },
        { name: "Por favor indíquenos como desea que lo contactemos", type: "Select", options: ["Llamada", "Whatsapp o msj", "email"] },
      ],
    },
  ];

  const validProductionAdminUrl = function (url = "") {
    if (process.env.REACT_APP_NODE_ENV === "TEST") {
      return `http://localhost:3030${url}`;
    } else {
      return `https://admin.ventadirekta.com${url}`;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let data = {
      name: event.target.name.value,
      phone: event.target.phone.value.replaceAll(" ", "").replace("+", ""),
      email: event.target.email.value,
      order_number: event.target.order_number.value,
      product_type: event.target.productType.value,
      form: productTypeOptions.find((item) => item.name == event.target.productType.value).value.map((item) => {return {"question": item.name, "answer": event.target[item.name.replaceAll(" ", "-")].value}})
    };

    if (orderValid) {
      try {
        const result = await fetch(validProductionAdminUrl("/api/v1/return-form"), {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        const submit_result = await result.json();

        console.log(submit_result)

        if (submit_result.success.show === true) {
          let type = submit_result.success.type;
          toast[type](submit_result.success.message);
        }
        if (submit_result.success.type === "success") {
          
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      toast.error("Por favor, revisa el número de orden");
    }

    console.log(data);
  };

  const { pageVariants } = useAnimations();

  return (
    <motion.div className="formPage" variants={pageVariants} initial="initial" animate="visible">
      <form onSubmit={handleSubmit}>
        {load ? (
          <>
            <GeneralInfo setCallBackIsValid={setCallBackIsValid} setOrderValid={setOrderValid} />
            <ProductType productTypeOptions={productTypeOptions} />

            <div className="formPage_submitButton">
              <MainButton text="Finalizar" type="submit" startIcon={<EastIcon />} />
            </div>
          </>
        ) : (
          <div style={{ width: "100%", height: "100vh", backgroundColor: "#fff", display: "inline-flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress style={{ color: "#000" }} />
          </div>
        )}
      </form>
    </motion.div>
  );
}

function GeneralInfo({ setCallBackIsValid, setOrderValid }) {
  return (
    <div className="section forms_section general_info_form_section">
      <div className="container">
        <div className="forms_sectionContent infoBoxContent">
          <div className="formDiv">
            <Heading text="QUIERO HACER UN CAMBIO O DEVOLUCIÓN" />
            <Text classText="formPage_subHeadings" text="Comienza completando tus datos básicos para que podamos ayudarte." />
            <div className="fields">
              <div className="item">
                <Text_input label="Nombre (*)" name="name" type="text" placeholder="Nombre" required={true} />
              </div>
              <div className="item">
                <PhoneNumber_input callBackIsValid={setCallBackIsValid} label="Teléfono (*)" name="phone" placeholder="Teléfono" required={true} />
              </div>
              <div className="item">
                <TextField_with_icon label="Correo electrónico (*)" name="email" type="email" placeholder="Correo electrónico" required={true} />
              </div>
              <div className="item">
                <InputVerifyOrder label="Número de Orden (*)" name="order_number" type="text" placeholder="STR-XXXX" setOrderValid={setOrderValid} required={true} />
              </div>
            </div>
          </div>
        </div>
        {/* --background image-- */}
        <picture className="sectionBgImage">
          <source media="(max-width:575px)" srcSet={mobile_bgImage} />
          <img src={bgImage} alt="Flowers" />
        </picture>
        {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
      </div>
    </div>
  );
}
function ProductType({ productTypeOptions }) {
  const [productType, setProductType] = useState(null);
  
  return (
    <div className="section forms_section general_info_form_section">
      <div className="container">
        <div className="forms_sectionContent infoBoxContent">
          <div className="formDiv">
            <Heading text="¿QUE PRODUCTO COMPRASTE?" />
            <div className="item" style={{ marginTop: 30 }}>
              <Select_input
                label="Selecciona el tipo de producto (*)"
                name="productType"
                options={productTypeOptions}
                optionTitle={"name"}
                width="50%"
                action={(selectedValue) => {
                  setProductType(selectedValue);
                }}
                required={true}
              />
            </div>
            <div className="fields">
              {productType == "Faja"
                ? productTypeOptions
                    .find((item) => item.name == "Faja")
                    .value.map((item) => {
                      if (item.type == "Select") {
                        return (
                          <div className="item">
                            <Select_input
                              key={item.name}
                              label={`${item.name} (*)`}
                              name={item.name.replaceAll(" ", "-")}
                              options={item.options}
                              optionLabel={false}
                              action={(selectedValue) => {
                                console.log(selectedValue);
                              }}
                              required={true}
                            />
                          </div>
                        );
                      } else if (item.type == "Input") {
                        return (
                          <div className="item">
                            <Text_input key={item.name} label={`${item.name} (*)`} name={item.name.replaceAll(" ", "-")} type="text" placeholder={item.placeholder} required={true} />
                          </div>
                        );
                      }
                    })
                : productType == "Jean"
                ? productTypeOptions
                    .find((item) => item.name == "Jean")
                    .value.map((item) => {
                      return (
                        <Select_input
                          key={item.name}
                          label={`${item.name} (*)`}
                          name={item.name.replaceAll(" ", "-")}
                          options={item.options}
                          optionLabel={false}
                          action={(selectedValue) => {
                            console.log(selectedValue);
                          }}
                          required={true}
                        />
                      );
                    })
                : productType == "Otro"
                ? productTypeOptions
                    .find((item) => item.name == "Otro")
                    .value.map((item) => {
                      return (
                        <Select_input
                          key={item.name}
                          label={`${item.name} (*)`}
                          name={item.name.replaceAll(" ", "-")}
                          options={item.options}
                          optionLabel={false}
                          action={(selectedValue) => {
                            console.log(selectedValue);
                          }}
                          required={true}
                        />
                      );
                    })
                : ""}
            </div>
          </div>
        </div>
        {/* --background image-- */}
        <picture className="sectionBgImage">
          <source media="(max-width:575px)" srcSet={mobile_bgImage} />
          <img src={bgImage} alt="Flowers" />
        </picture>
        {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
      </div>
    </div>
  );
}

export default FormReturns;
