import React from 'react'
import { motion } from "framer-motion"
import useAnimations from '../../../Auth/useAnimations'
import DasktopDIv from "./BigCardDiv/BigCardDiv"
import PaginationReviewsDiv from "./PaginationReviewsDiv/PaginationReviewsDiv"

export default function Testimonials() {
    const { pageVariants } = useAnimations()
    return (
        <motion.div className='TestimonialesPage' variants={pageVariants} initial="initial" animate="visible">
            <DasktopDIv />
            <PaginationReviewsDiv />
        </motion.div>
    )
}
