import React from "react";
import Fab from "@mui/material/Fab";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
function Whatsapp_btn() {
	return (
		<div className="floating_buttos whatsApp_floating_button">
			<Link to="https://wa.me/+17867567015" target="_blank">
				<Fab sx={{ background: "#25D366", color: "white", height: { xs: "45px", md: "50px", lg: "60px" }, width: { xs: "45px", md: "50px", lg: "60px" }, ":hover": { background: "#25D366", color: "white", scale: "1.1" } }} aria-label="add">
					<WhatsAppIcon sx={{ width: "60%", height: "60%" }} />
				</Fab>
			</Link>
		</div>
	);
}

export default Whatsapp_btn;
