import React from "react";
import { motion } from "framer-motion";
import ReflectHeading2 from "../../CommonComponent/Typography/ReflectHeading2";
import useAnimations from "../../../Auth/useAnimations";
import bgImage from "../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";
import {} from "./style.css";

export const Index = () => {
	const { textAnimation } = useAnimations();

	return (
		<div>
			<div className="TYFR">
				<ReflectHeading2 boldText="GRACIAS POR REGISTRARTE" />
				<motion.h4 variants={textAnimation} initial="offscreen" whileInView="onscreen">
					hemos recibido tu información, te contactaremos pronto...
				</motion.h4>
			</div>

			<picture className="sectionBgImage">
				<source media="(max-width:575px)" srcSet={mobile_bgImage} />
				<img src={bgImage} alt="Flowers" />
			</picture>
		</div>
	);
};
