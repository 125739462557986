import React, { useState } from 'react'
import Text from '../../../CommonComponent/Typography/Text'
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Typography } from '@mui/material';
import Text_input from "../../../CommonComponent/Inputs/Text_input";
// --icons
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import PinterestIcon from '@mui/icons-material/Pinterest';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import GroupIcon from '@mui/icons-material/Group';
import { FaTiktok } from 'react-icons/fa'

function Social_option(props) {
    
    return (
        <div className="social_options">
            <h2 style={{ fontWeight: "500" }}>¿Cómo supiste de nosotros?</h2>
            <div className="options">
                {/* --- */}
                <div className={`item  ${props.socialOption === "Instagram" && "active"}`} onClick={() => props.setsocialOption("Instagram")}>
                    <label htmlFor="Instagram">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <InstagramIcon sx={{ color: "white" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Instagram</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="Instagram" name='socialOption' />
                </div>
                {/* --- */}
                <div className={`item ${props.socialOption === "Facebook" && "active"}`} onClick={() => props.setsocialOption("Facebook")}>
                    <label htmlFor="facebook">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <FacebookRoundedIcon sx={{ color: "white" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Facebook</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="facebook" name='socialOption' />
                </div>

                {/* --- */}
                <div className={`item ${props.socialOption === "Tiktok" && "active"}`} onClick={() => props.setsocialOption("Tiktok")}>
                    <label htmlFor="tiktok">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <FaTiktok style={{ color: "white", fontSize: "1.5rem" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Tiktok</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="tiktok" name='socialOption' />
                </div>

                {/* --- */}
                <div className={`item  ${props.socialOption === "Pinterest" && "active"}`} onClick={() => props.setsocialOption("Pinterest")}>
                    <label htmlFor="pinters">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <PinterestIcon sx={{ color: "white" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Pinteres</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="pinters" name='socialOption' />
                </div>

                {/* --- */}
                <div className={`item ${props.socialOption === "Friends" && "active"}`} onClick={() => props.setsocialOption("Friends")}>
                    <label htmlFor="amigos">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <GroupIcon sx={{ color: "white" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Amigos</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="amigos" name='socialOption' />
                </div>

                {/* --- */}
                <div className={`item ${props.socialOption === "Others" && "active"}`} onClick={() => props.setsocialOption("Others")}>
                    <label htmlFor="otros">
                        <Box sx={{ display: "flex", gap: "10px", width: "100%", padding: " 1rem", borderRadius: "5px", boxShadow: "0px 0px 10px rgba(100, 100, 111, 0.2) ", alignItems: "center" }}>
                            <Box className="socialIcons" sx={{ borderRadius: "50%", background: "#000000c4", padding: "6px", display: "flex" }}>
                                <AddCircleIcon sx={{ color: "white" }} />
                            </Box>
                            <Box className='socialText' >
                                <Typography variant='subtitle1' sx={{ color: "#808080", fontSize: "1.2rem" }}>Otros</Typography>
                            </Box>
                        </Box>
                    </label>
                    <input type="radio" id="otros" name='socialOption' />
                </div>

            </div>
            {props.socialOption === "Others" ? (
                <>
                    <Text_input label="Red social (*)" name="social_media" type="text" placeholder="Red social" />
                </>
            ) : (
                ""
            )}
        </div>
    )
}

export default Social_option