import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
//--images--
import img1 from '../../../../Images/AboutPage/forthSectionimg1.webp'
import img2 from '../../../../Images/AboutPage/forthSectionimg2.webp'
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'

// --bg lines--
import linesBg from '../../../../Images/AboutPage/forthSectionBgLines.svg'

export default function ForthSection() {
    const { textAnimation } = useAnimations()
    return (
        <div className="section forthSection">
            <div className="container forthContainer">

                <div className="boxes">
                    <div className="box box1">
                        <LazyLoadImage src={img1} />
                    </div>
                    <div className="box box2">
                        <div className="content">
                            <motion.p className="heading" variants={textAnimation} initial="offscreen" whileInView="onscreen"> <span> somos tu aliado </span>                              comercial</motion.p>
                            <motion.p className="pera" variants={textAnimation} initial="offscreen" whileInView="onscreen">ELIGE TU COMODIDAD</motion.p>
                            <ul>
                                <motion.li variants={textAnimation} initial="offscreen" whileInView="onscreen"><span className='num'>01</span> <span> <span className='brack'> Despachamos </span>tus pedidos </span></motion.li>
                                <motion.li variants={textAnimation} initial="offscreen" whileInView="onscreen"><span className='num'>02</span> <span> <span className='brack'> Administramos  </span>tu web </span></motion.li>
                                <motion.li variants={textAnimation} initial="offscreen" whileInView="onscreen"><span className='num'>04</span> <span> <span className='brack'> Atendemos en Customer</span><span className='brack'> Services tus solicitudes </span> y las de tus clientes </span></motion.li>
                                <motion.li variants={textAnimation} initial="offscreen" whileInView="onscreen"><span className='num'>03</span> <span> <span className='brack'> Actualizamos </span>tu portafolio</span></motion.li>
                            </ul>
                        </div>
                        <div className="img">
                            <LazyLoadImage src={img2} />
                        </div>
                    </div>
                </div>
                {/* -- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                    <img src={pentagon} alt="element" className="forth" />
                </div>

                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="lines" />
                </picture>
            </div>
        </div>
    )
}
