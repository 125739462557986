import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// --images--
import bigImg from '../../../../Images/AboutPage/heroSectionBig.png'
import smallImg from '../../../../Images/AboutPage/heroSectionSmall.png'
import mobileBigImg from '../../../../Images/AboutPage/heroSectionMobileBig.png'
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'

// --bg lines--
import linesBg from '../../../../Images/AboutPage/heroSectionLines.svg'
export default function BannerSection() {
    return (
        <div className="section bannerSection">
            <div className="container bannerContainer">
                <div className="Boxes">
                    <div className="box BigBox">
                        <LazyLoadImage alt="#" src={bigImg} effect="blur" className='dasktopImg' />
                    </div>

                    <div className="box mobileBox">
                        <LazyLoadImage alt="#" src={mobileBigImg} effect="blur" className='dasktopImg' />
                    </div>
                    <div className="box SmallBox">
                        <LazyLoadImage alt="#" src={smallImg} effect="blur" className='lazyimg' />
                    </div>
                </div>
                {/* -- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                    <img src={pentagon} alt="element" className="forth" />
                </div>

                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="lines" />
                </picture>
            </div>
        </div>
    )
}
