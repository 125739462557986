import React, { useRef } from 'react'
// --swiper--
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper";
import HeroSlider_card from './HeroSlider_card';

// --icon--
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
// --hook--
import useAuth from '../../../../Auth/useAuth';
function HeroSlider() {
    const { SwiperhandlePrev, SwiperhandleNext } = useAuth()
    const sliderRef = useRef(null);
    return (
        <>
            <div className="heroSection">
                <div className="heroSlider">
                    <Swiper
                        style={{
                            "--swiper-pagination-color": "var(--white-color)",
                            "--swiper-pagination-bullet-inactive-color": "var(--white-color)",
                            "--swiper-pagination-bullet-inactive-opacity": "0.6",
                            "--swiper-pagination-bullet-size": "10px",
                        }}
                        cssMode={false}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        mousewheel={false}
                        keyboard={false}
                        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                        className="mySwiper swiper"
                        ref={sliderRef}
                        breakpoints={{
                            575: {
                                pagination: false,
                            }
                        }}
                    >
                        {/* slide */}
                        <SwiperSlide>
                            {({ isActive }) => (
                                <HeroSlider_card active={isActive} />
                            )}
                        </SwiperSlide>
                        <SwiperSlide>
                            {({ isActive }) => (
                                <HeroSlider_card active={isActive} />
                            )}
                        </SwiperSlide>
                        {/* //slide */}
                        <div className="customSlider_button">
                            <button onClick={() => SwiperhandlePrev(sliderRef)}><WestIcon /></button>
                            <button onClick={() => SwiperhandleNext(sliderRef)}><EastIcon /></button>
                        </div>
                    </Swiper>

                </div>

            </div>
        </>
    )
}

export default HeroSlider