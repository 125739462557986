import React from 'react'
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import EastIcon from '@mui/icons-material/East';
import { Link } from "react-router-dom";

import profileImage from '../../../../Assets/images/resources/avatar.webp'
import profileGabriella from '../../../../Assets/images/resources/gabriella.webp'
import profileIris from '../../../../Assets/images/resources/iris.webp'
import profileIrisSanchez from '../../../../Assets/images/resources/iris_sanchez.webp'

function Testimonial_AvtarBox() {
    return (
        <div className="Testimonail_AvtarBox">
            <AvatarGroup>
                <Avatar alt="Remy Sharp" sx={{ height: "35px", width: "35px" }} src={profileImage} />
                <Avatar alt="Travis Howard" sx={{ height: "35px", width: "35px" }} src={profileGabriella} />
                <Avatar alt="Cindy Baker" sx={{ height: "35px", width: "35px" }} src={profileIris} />
                <Avatar alt="Agnes Walker" sx={{ height: "35px", width: "35px" }} src={profileIrisSanchez} />
            </AvatarGroup>
            <Link to="/testimonials" className='linkButton'><EastIcon /> VER TESTIMONIOS</Link>
        </div>
    )
}

export default Testimonial_AvtarBox