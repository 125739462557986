import React from 'react'
import { motion } from 'framer-motion'
import useAnimations from '../../../Auth/useAnimations'
import BannerSection from './BannerSection/BannerSection'
import SecondSection from './SecondSection/SecondSection'
import ThiredSection from './ThiredSection/ThiredSection'
import ForthSection from './ForthSection/ForthSection'
import MoreInfoForm from '../Home/MoreInfoForm/MoreInfoForm'

// --

export default function About() {
    const { pageVariants } = useAnimations()
    return (
        <motion.div className='AboutPage' variants={pageVariants} initial="initial" animate="visible">
            <BannerSection />
            <SecondSection />
            <ThiredSection />
            <ForthSection />
            <MoreInfoForm />
        </motion.div>
    )
}
