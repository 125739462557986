import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import Text from '../../../CommonComponent/Typography/Text'
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router';

function Register_content() {
    const navigate = useNavigate()
    return (
        <div className="registerInfo">
            <div className="heading">
                <Text classText="headingText" text="¿ESTAS LISTO?" />
                <Text classText="second_headingText" text="CREA YA TU TIENDA" />
            </div>
            <Text text="Comienza a ganar dinero extra con Ventadirekta. Vende productos colombianos sin preocuparte por nada. Nosotros nos encargamos de crear tu sitio web, facturación, despachos, logística e incluso servicio al cliente." classText="pera" />
            <p className="subHeading"  ><span> ¿QUÉ </span>ESPERAS?</p>
            <MainButton text="REGISTRATE YA" startIcon={<EastIcon />} action={() => { navigate("/form") }} />
        </div>
    )
}

export default Register_content