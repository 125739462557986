import React from 'react'
import Fab from '@mui/material/Fab';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import NorthIcon from '@mui/icons-material/North';
function GoTop_btn() {
    return (
        <div className="floating_buttos goTop_floating_button">
            <Fab color="primary" sx={{ height: { xs: "45px", md: "50px", lg: "60px" }, width: { xs: "45px", md: "50px", lg: "60px" } }} aria-label="add" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                <NorthIcon sx={{ width: "50%", height: "50%" }} />
            </Fab>
        </div>
    )
}
export default GoTop_btn