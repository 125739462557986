import React from 'react'
import { motion } from "framer-motion"
import useAnimations from '../../../Auth/useAnimations'
import BannerSection from './BannerSection/BannerSection'
import SecondSection from './SecondSection/SecondSection'
import ThiredSection from './ThiredSection/ThiredSection'
import ForthSection from './ForthSection/ForthSection'
import FifthSection from './FifthSection/FifthSection'
import MoreInfoForm from '../Home/MoreInfoForm/MoreInfoForm'

export default function WantToSell() {
    const { pageVariants } = useAnimations()
    return (
        <motion.div className='WantToSellPage' variants={pageVariants} initial="initial" animate="visible">
            <BannerSection />
            <SecondSection />
            <ThiredSection />
            <ForthSection />
            <FifthSection />
            {/* ---fifth section is copy of home page form section---- */}
            <MoreInfoForm />

        </motion.div>
    )
}
