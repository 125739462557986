import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { purple } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';

const ColorButton = styled(Button)(({ theme }) => ({
    padding: "0.7rem 3rem",
    borderRadius: "0px",
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: "var(--one-color)",
    '&:hover': {
        backgroundColor: "var(--primary-color)",
    },
}));

function MainButton({ text, type, action, startIcon }) {
    return (
        <ColorButton type={type} variant="contained" startIcon={startIcon} onClick={action} sx={{ textDecoration: "underline", fontFamily: "var(--heading-font-family)" }}>{text}</ColorButton>
    )
}

export default MainButton