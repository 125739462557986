import React, { useState } from 'react'
import ReflectHeading from '../../../CommonComponent/Typography/ReflectHeading'
import downarrowImage from '../../../../Images/DownArrow_image.png'
import Benefits_card from './Benefits_card'
import EastIcon from '@mui/icons-material/East';
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import MainButton from '../../../CommonComponent/Button/MainButton';
// --images--
import img1 from '../../../../Images/HomePage/Benifits/Rectangle 1259.webp'
import img2 from '../../../../Images/HomePage/Benifits/Rectangle 1262.webp'
import img3 from '../../../../Images/HomePage/Benifits/Rectangle 1263.webp'
import img4 from '../../../../Images/HomePage/Benifits/Rectangle 1267.webp'
import bgImage from '../../../../Images/HomePage/Benifits/bg.png'
import mobile_bgImage from '../../../../Images/HomePage/Benifits/mobileBg.png'

import { useNavigate } from 'react-router';

function Benefits() {
    // --slider active state--
    const [sliderActive, setsliderActive] = useState("")
    const setActiveValue = (e) => {
        console.info(window.screen.width)
        if (window.screen.width > 1199) {
            if (sliderActive == "" || e != sliderActive) {
                setsliderActive(() => e)
            } else {
                setsliderActive(() => "")
            }
        }
    }
    // ---benefits data---
    const benefitData = [
        {
            id: 22,
            text: "Crea tu propio catálogo de productos.",
            img: img1,
            previewImage: img1,
        },
        {
            id: 23,
            text: "No requieres inversión inicial.",
            img: img2,
            previewImage: img2,
        },
        {
            id: 24,
            text: "Sin mínimos de compra.",
            img: img3,
            previewImage: img3,
        },
        {
            id: 25,
            text: "Cuenta siempre con asesoría personalizada.",
            img: img4,
            previewImage: img4,
        },
    ]
    return (
        <div className="section benefitsSection">
            <div className="container">
                <div className="benefitsContent">
                    {/* -- */}
                    <div className="heading">
                        <ReflectHeading boldText="BENEFICIOS" />
                        <img src={downarrowImage} alt="#" />
                    </div>
                    {/* -- */}
                    <div className={`benefitsSlider ${sliderActive != "" ? "active" : ""}`}>

                        <Swiper
                            slidesPerView="auto"
                            spaceBetween={30}
                            breakpoints={{
                                1199: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                                },
                            }}
                            className="benefit_Slider swiper"
                        >
                            {benefitData && benefitData.map((e, index) => {
                                return (
                                    <SwiperSlide className={`${sliderActive == e.id ? "active" : ""}`} key={e.id} onClick={() => setActiveValue(e.id)}>
                                        <Benefits_card index={index} text={e.text} img={e.img} previewImage={e.previewImage}/>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                    <div className='stepsMain_Button'>
                        <a href='#MoreInfoForm_Section_id'>
                            <MainButton text="QUIERO EMPEZAR" startIcon={<EastIcon />} />
                        </a>
                    </div>
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobile_bgImage} />
                    <img src={bgImage} alt="Flowers" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default Benefits