import React, { useContext } from "react";
import ReflectHeading2 from "../../CommonComponent/Typography/ReflectHeading2";
import useAnimations from "../../../Auth/useAnimations";
import bgImage from "../../../Images/FormsPage/backgroundImages/general_info.png";
import mobile_bgImage from "../../../Images/FormsPage/backgroundImages/general_info_mobile_bg.png";
import {} from "./style.css";

import { LanguageContext } from "../../../Context/Language";

function createMarkup(string) {
    return { __html: string };
}

export const Index = () => {
    const { textAnimation } = useAnimations();
	const { languageData } = useContext(LanguageContext);
    
	return (
		<div className="default-page">
			<div className="default-page-section">
				<ReflectHeading2 boldText={languageData.translations["translate-faqs-body-1"]} />
				<div className="default-page-p" dangerouslySetInnerHTML={createMarkup(languageData.translations["translate-faqs-body-2"])}>
                </div>
			</div>

			<picture className="sectionBgImage">
				<source media="(max-width:575px)" srcSet={mobile_bgImage} />
				<img src={bgImage} alt="Flowers" />
			</picture>
		</div>
	);
};
