import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Input_Label from "./Input_Label";
import countries from "../../../Assets/countries.json";

function CountrySelect(props) {
	return (
		<>
			<Input_Label label={props.label} />
			<Autocomplete
                // sx={{ width: 300}}
                options={countries}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                        alt=""
                    />
                    {option.label}
                    </Box>
                )}
                renderInput={(params) => {
                    return(
                        <TextField
                            {...params}
                            // label="País"
                            required={props.required}
                            variant="standard" 
                            name={props.name}
                            xs={{ m: 0}}
                            inputProps={{
                                ...params.inputProps
                            }}
                        />
                )}}
                />
		</>
	);
}

export default CountrySelect;
