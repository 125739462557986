import React from 'react'
import bigImage from '../../../../Images/HomePage/register/Rectangle 1032.webp'
import smallImage from '../../../../Images/HomePage/register/Rectangle 1033.webp'

function Register_images() {
    return (
        <div className="registerImages">
            <div className="bigImage">
                <img src={bigImage} alt="#" />
            </div>
            <div className="smallImage">
                <img src={smallImage} alt="#" />
            </div>
        </div>
    )
}

export default Register_images