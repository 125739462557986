import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input_Label from './Input_Label';

function TextField_password(props) {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return (
        <>
            <Input_Label label={props.label} />
            <FormControl sx={{ width: '100%' }} variant="standard">

                <Input
                    id={props.label}
                    onChange={props.action}
                    type={showPassword ? 'text' : 'password'}
                    placeholder={props.placeholder}
                    error={props.error}
                    inputProps={{
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    name={props.name}
                    required={props.required}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                // onMouseDown={props.action}
                                sx={{ color: "var(--one-color)", padding: "0" }}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {props.error ? (
                    
                    <FormHelperText>{props.helperText}</FormHelperText>
                ) : ''}
            </FormControl>
        </>
    )
}

export default TextField_password