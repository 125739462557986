import * as React from 'react';
import InputLabel from './Input_Label'
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';


function TextField_with_icon({ label, action, placeholder, icon, type, popoverText, name, ...props }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const popoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const popoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


    return (<>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={popoverClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Typography sx={{ p: 2 }}>{popoverText}</Typography>
        </Popover>
        <InputLabel label={label} />
        <TextField
            // label={label}
            onChange={action}
            type={type}
            fullWidth
            placeholder={placeholder}
            name={name}
            required={props.required}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{ color: "var(--one-color)", cursor: "pointer" }} aria-describedby={id} onClick={popoverOpen}>
                        {icon}
                    </InputAdornment>
                ),
            }}
            variant="standard"
        />
    </>
    )
}

export default TextField_with_icon