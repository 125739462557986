import React, { useState } from "react";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
//--elements--
import starElement from "../../../../Images/elements/star.svg";
import downArrow from "../../../../Images/elements/downArrow.svg";
import pentagon from "../../../../Images/elements/pentagon.svg";
// ---images--
import profileImage from "../../../../Assets/images/resources/avatar.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function PaginationReviewsDiv() {
	const cardsData = [
		{
			id: "testi4",
			name: "Luisa Avalo",
			review: "El proyecto de Ventadirekta me ha parecido fantástico….Los productos son buenísimos y el delivery es super fácil. Se los recomiendo",
			img: profileImage,
			video: false,
		},
		{
			id: "testi5",
			name: "Yesenia Rosa",
			review: "Con Ventadirekta tengo la oportunidad de atender a mis clientes locales como los antiguos de la Florida desde la comodidad de mi casa.  Ventadirekta se encarga de los despachos y toda la logística; tiene cobertura de envíos a todo Estados Unidos, es un negocio sin fronteras.",
			img: profileImage,
			video: false,
		},
		{
			id: "testi6",
			name: "Nicole",
			review: "Saludos mi nombre es Nicole, soy de Puerto Rico, y soy parte del equipo de Ventadirekta. Le doy gracias al grupo por enviarme las capacitaciones porque gracias a eso he podido brindar mejor información a mis clientas, para tener conocimiento de cada producto y así poder brindarle mejores servicios, así que los recomiento 100%, muchas gracias y esto va para largo.",
			img: profileImage,
			video: false,
			video_link: "https://www.youtube.com/embed/XdW3UvmgZgg",
		},
        {
			id: "testi7",
			name: "Selene",
			review: "Mi nombre es Selene y estoy desde Agosto del año pasado, a mi gusta que hay mucha variedad de amrcas, y siempre el acompañamiento que se tiene, mi producto favorito son la ropa interior de compresión y los shorts levantacola, la calidad de los productos es única!",
			img: profileImage,
			video: false,
		},
        {
			id: "testi8",
			name: "Andrea Jaramillo",
			review: "Me gusta por las facilidades que dan para realizar las ventas y los productos están variados",
			img: profileImage,
			video: false,
		},
	];

	// --paginationValue--
	const [paginationValue, setpaginationValue] = useState(1);
	return (
		<div className="section">
			<div className="container paginationReviews">
				<div className="reviewCards">
					{/* --- */}
					{cardsData.slice(paginationValue * 12 - 12, paginationValue * 12).map((e, index) => (
						<Card key={e.index} sx={{ minWidth: 275, border: "1px solid #000" }}>
							<CardContent>
                                <Avatar sx={{margin: "auto"}} src={e.img} aria-label="recipe"></Avatar>
								<Typography variant="h5" component="div" sx={{ textAlign: "center", margin: "10px 0" }}>
									{e.name}
								</Typography>
								<Typography variant="body2" sx={{ textAlign: "center" }}>
									{e.review}
								</Typography>
							</CardContent>
						</Card>
					))}
				</div>
				<div className="paginationNum">
					<Pagination count={parseInt(cardsData.length / 12) + 1} shape="rounded" hidePrevButton hideNextButton onChange={(e) => setpaginationValue(Number(e.currentTarget.textContent))} />
					<span>Siguiente</span>
				</div>

				<div className="elementsDiv">
					<img src={starElement} alt="element" className="first" />
				</div>
			</div>
		</div>
	);
}
