import React from 'react'
import ReflectHeading from '../../CommonComponent/Typography/ReflectHeading'
import MainButton from '../../CommonComponent/Button/MainButton'
import EastIcon from '@mui/icons-material/East';
import useAnimations from '../../../Auth/useAnimations'
import { motion } from 'framer-motion'
//--elements--
import starElement from '../../../Images/elements/star.svg'
import pentagonElement from '../../../Images/elements/pentagon.svg'
//--bglines--
import linesBg from '../../../Images/AcademyPage/AcademeSectionBgLines.svg'


export default function AcademySection() {
    const { textAnimation } = useAnimations()
    return (
        <div className="section academySection">
            <div className="container academyContainer">
                <div className="heading">
                    <ReflectHeading boldText="Ventadirekta Academy" />
                </div>
                {/* ---- */}
                <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="item heading">Para Empezar</motion.div>
                <div className="cards">
                    <div className="card">
                        <div className="title">
                            <p title="Aprende a conocer tus medidas usando un dólar con Ventadirekta">Aprende a conocer tus medidas usando un dólar con Ventadirekta</p>
                            <small>24-07-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/K7Bo88Hgjaw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Cómo Tomar Mis Medidas Correctamente Para Usar Fajas Colombianas VENTADIREKTA">Cómo Tomar Mis Medidas Correctamente Para Usar Fajas Colombianas VENTADIREKTA</p>
                            <small>24-07-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/ywaxEh1nEOQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Conoce los TIPOS DE CUERPO femeninos para Fajas Colombianas VENTADIREKTA">Conoce los TIPOS DE CUERPO femeninos para Fajas Colombianas VENTADIREKTA</p>
                            <small>24-07-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/4JhpbBgA18M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Ventas al por mayor COMO VENDER ONLINE VENTADIREKTA">Ventas al por mayor COMO VENDER ONLINE VENTADIREKTA</p>
                            <small>24-07-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/RWpBNphG5zQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Storytime de cómo empezamos">Storytime de cómo empezamos</p>
                            <small>24-07-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/zegvqN6GhRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="item heading">Productos y Marcas</motion.div>
                <div className="cards">
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas para Uso Diario, Post quirúrgico y Postparto Bling Shapers">Fajas Colombianas para Uso Diario, Post quirúrgico y Postparto Bling Shapers</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/pZNo_p9mKo8?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="FAJAS DIANE &amp; GEORDI: Diseños de Fajas Colombianas para Todo Tipo de Cuerpo 1/3">FAJAS DIANE &amp; GEORDI: Diseños de Fajas Colombianas para Todo Tipo de Cuerpo 1/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/D2qPvE193eo?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="DIANE &amp; GEORDI: Fajas Colombianas Postparto, postquirurgicas y de uso diario 2/3">DIANE &amp; GEORDI: Fajas Colombianas Postparto, postquirurgicas y de uso diario 2/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/6bfU6i0O8dw?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="DIANE &amp; GEORDI:Fajas Colombianas para Hombres después de cirugías y accesorios 3/3">DIANE &amp; GEORDI:Fajas Colombianas para Hombres después de cirugías y accesorios 3/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/aVQ76R0MRbQ?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Ventajas ofrecidas por las fajas colombianas LOWLA">Ventajas ofrecidas por las fajas colombianas LOWLA</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/bP3CPs88fow?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas Modeladoras Mariae Postquirúrgicas, Postparto VENTADIREKTA 1/3">Fajas Colombianas Modeladoras Mariae Postquirúrgicas, Postparto VENTADIREKTA 1/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/GFoOw-_K1z8?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas Mariae Estilos, Modelos y Siluetas VENTADIREKTA 2/3">Fajas Colombianas Mariae Estilos, Modelos y Siluetas VENTADIREKTA 2/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/rPqW2bAcw7w?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Mariae Fajas Colombianas, fajas para vestidos, tablas abdominales, brasier, accesorio VENTADIREKTA 3/3">Mariae Fajas Colombianas, fajas para vestidos, tablas abdominales, brasier, accesorio VENTADIREKTA 3/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/Mv8FYj4kUho?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas Hechas de Materiales de Alta Calidad VENTADIREKTA">Fajas Colombianas Hechas de Materiales de Alta Calidad VENTADIREKTA</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/ddd7TjIkZkc?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas para Todo Tipo de Cuerpos de MYD 1/3">Fajas Colombianas para Todo Tipo de Cuerpos de MYD 1/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/XpFqcqh6IHk?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Catálogo fajas colombianas reductoras, post quirúrgicas y postparto MYD 2/3">Catálogo fajas colombianas reductoras, post quirúrgicas y postparto MYD 2/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/YbOPTQHNBkM?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas de uso diario, post quirúrgica y post parto MYD 3/3">Fajas Colombianas de uso diario, post quirúrgica y post parto MYD 3/3</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/GN1aTfbzY2s?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Romanza Fajas Colombianas para Mujer de Uso Diario y Postparto 1/2">Romanza Fajas Colombianas para Mujer de Uso Diario y Postparto 1/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/ERvs41b4C9c?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Catálogo de Fajas Colombianas para diferentes tipos de siluetas femeninas ROMANZA 2/2">Catálogo de Fajas Colombianas para diferentes tipos de siluetas femeninas ROMANZA 2/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/eAQjcpTTt9g?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Diseños de Fajas acorde a los Tipos de Siluetas y Necesidades 1/2">Diseños de Fajas acorde a los Tipos de Siluetas y Necesidades 1/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/Ax-LZuMZcbE?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Catalogo de Fajas Colombianas para moldear el cuerpo Review SONRYSE 2/">Catalogo de Fajas Colombianas para moldear el cuerpo Review SONRYSE 2/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/OIAQBVXzL7o?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="SONRYSE: Calidad y versatilidad en prendas de compresión 1/2">SONRYSE: Calidad y versatilidad en prendas de compresión 1/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/HJk04VEXm14?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Catálogo de Fajas Colombianas para moldear el cuerpo Review SONRYSE 2/2">Catálogo de Fajas Colombianas para moldear el cuerpo Review SONRYSE 2/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/-4ZXcp2pJ4c?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Innovación y Calidad de Fajas de Compresión Colombiana UPLADY 1/2">Innovación y Calidad de Fajas de Compresión Colombiana UPLADY 1/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/zMkaWFistKg?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas postparto, postquirurgicas y de uso diario Review UPLADY 2/2">Fajas Colombianas postparto, postquirurgicas y de uso diario Review UPLADY 2/2</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/U8XJXoWu1AU?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="CÓMO PONERSE UNA FAJA COLOMBIANA">CÓMO PONERSE UNA FAJA COLOMBIANA</p>
                            <small>30-06-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/X3i72HJBcBI?list=PL5NIzOg_nJ77pyPEowMecegG63-k-7Nee" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="item heading">Configuración de Tienda</motion.div>
                <div className="cards">
                    <div className="card">
                        <div className="title">
                            <p title="Crear tienda online desde cero PASO a PASO Ventadirekta">Crear tienda online desde cero PASO a PASO Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/_-Bjuk7EmNs?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Paso a paso de cómo funciona tu tienda online Ventadirekta">Paso a paso de cómo funciona tu tienda online Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/cnMv7lXz5JQ?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Enlazar Instagram con mi tienda online paso a paso en Ventadirekta">Enlazar Instagram con mi tienda online paso a paso en Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/zEsyQ6bW3gE?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Cómo conectar la página web con Facebook en Ventadirekta">Cómo conectar la página web con Facebook en Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/BwMxmt9OdmQ?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="PASO A PASO cómo conectar TWITTER con tu TIENDA ONLINE de Ventadirekta">PASO A PASO cómo conectar TWITTER con tu TIENDA ONLINE de Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/GrfXLASSN9A?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Como crear un descuento en tu tienda online de VENTADIREKTA">Como crear un descuento en tu tienda online de VENTADIREKTA</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/sk_PcEPTgRo?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Video tutorial de Como acceder a mi página web de Ventadirekta">Video tutorial de Como acceder a mi página web de Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/6H_991qRR-I?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Como aplicar un descuento desde la web de VENTADIREKTA">Como aplicar un descuento desde la web de VENTADIREKTA</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/auKKbs6ZJEs?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Aprende cómo funciona tu tienda virtual de Ventadirekta">Aprende cómo funciona tu tienda virtual de Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/ZlttxfvcH2A?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Como añadir referidos a mi TIENDA POR INTERNET de VENTADIREKTA">Como añadir referidos a mi TIENDA POR INTERNET de VENTADIREKTA</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/stnlEv5h9J0?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Cómo llenar el formulario W9 y políticas de tu tienda online en Ventadirekta">Cómo llenar el formulario W9 y políticas de tu tienda online en Ventadirekta</p>
                            <small>04-11-2021</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/pkYvEs3_Cls?list=PL5NIzOg_nJ743fONxGl0x1i7gkVKDRN13" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen" className="item heading">Lives</motion.div>
                <div className="cards">
                    <div className="card">
                        <div className="title">
                            <p title="Conoce cómo lanzar tu tienda Web con Éxito con Ventadirekta">Conoce cómo lanzar tu tienda Web con Éxito con Ventadirekta</p>
                            <small>11-10-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/qHYpE5tvhFk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Vender por internet conviértete en una experta Viacademy Ventadirekta">Vender por internet conviértete en una experta Viacademy Ventadirekta</p>
                            <small>10-10-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/jUS1xm86IRU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas: Conoce sus características PARTE 1/3">Fajas Colombianas: Conoce sus características PARTE 1/3</p>
                            <small>22-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/r2H8Lt5Sv-k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas: Conoce sus características PARTE 2/3">Fajas Colombianas: Conoce sus características PARTE 2/3</p>
                            <small>22-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/7VV_aeSejHM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Fajas Colombianas: Conoce sus características PARTE 3/3">Fajas Colombianas: Conoce sus características PARTE 3/3</p>
                            <small>22-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/a6_s3m1PXbs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 1/3">Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 1/3</p>
                            <small>14-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/KaxUyUd4nJo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 2/3">Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 2/3</p>
                            <small>14-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/gh__tXDvPXc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 3/3">Te contamos cómo elegir la FAJA COLOMBIANA CORRECTA? PARTE 3/3</p>
                            <small>14-09-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/xfWzDOH2Kzc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="card">
                        <div className="title">
                            <p title="WEBINAR: 10 ACCIONES NECESARIAS PARA AUMENTAR TUS VENTAS PARTE 2/2">WEBINAR: 10 ACCIONES NECESARIAS PARA AUMENTAR TUS VENTAS PARTE 2/2</p>
                            <small>12-11-2022</small>
                        </div>
                        <div className="videoDiv">
                            <iframe src="https://www.youtube.com/embed/0AL8uJgx-FY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
                {/* ---- */}
                <div className="btn">
                    <MainButton text="Registrarme ahora" startIcon={<EastIcon />} />
                </div>

                {/* ---elements--- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={pentagonElement} alt="element" className="second" />
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="lines" />
                </picture>


            </div>
        </div>
    )
}
