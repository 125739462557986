import React from 'react'
import HowTextContent from './HowTextContent'
import HowToImageBox from './HowToImageBox'
import HowToTabs from './HowToTabs'
import bgImage from '../../../../Images/HomePage/HowToWork/bg-final.png'
import mobile_bgImage from '../../../../Images/HomePage/HowToWork/mobileBg.png'
function HowToWork() {

    return (
        <div className="howToWork section">
            <div className="container">
                <div className="contentContainer">
                    {/* --text contnet-- */}
                    <HowTextContent />
                    <HowToImageBox />
                    <HowToTabs />
                </div>
                {/* --background image-- */}
                <picture className='sectionBgImage'>
                    <source media="(max-width:575px)" srcSet={mobile_bgImage} />
                    <img src={bgImage} alt="Flowers" />
                </picture>
                {/* <img src={bgImage} alt="#" className='sectionBgImage' /> */}
            </div>
        </div>
    )
}

export default HowToWork