import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import { useNavigate } from 'react-router';



export default function Errorpage() {
    const navigate = useNavigate()
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                minHeight: '100vh',
                backgroundColor: "white",
            }}
        >
            <Typography variant="h1" style={{ color: 'var(--primary-color)' }}>
                404
            </Typography>
            <Typography variant="h6" style={{ color: 'var(--primary-color)', py: 3 }}>
                The page you’re looking for doesn’t exist.
            </Typography>
            <br />
            <Button variant="contained" onClick={() => navigate(-1)}>Back Home</Button>
        </Box>
    );
}