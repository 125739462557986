import React from 'react'
import homeSliderPerson from "../../../../Images/HomePage/HowToWork/Rectangle 1163.png"
import homeSliderPerson2 from "../../../../Images/HomePage/HowToWork/Rectangle 1164.png"

function HowToImageBox() {
    return (
        <div className="howToImageBox">
            <img src={homeSliderPerson2} className="img1" />
            <img src={homeSliderPerson} className="img2" />
        </div>
    )
}

export default HowToImageBox