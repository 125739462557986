import React from 'react'
import { motion } from "framer-motion"
import useAnimations from '../../../Auth/useAnimations'
import BannerSection from './BannerSection/BannerSection'
import ContactForm from './ContactForm/ContactForm'

function Contact() {
    const { pageVariants } = useAnimations()
    return (

        <motion.div className='contactPage' variants={pageVariants} initial="initial" animate="visible">
            <BannerSection />
            <ContactForm />
        </motion.div>

    )
}

export default Contact