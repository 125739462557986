import React from "react";
import ReflectHeading from "../../../CommonComponent/Typography/ReflectHeading";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar } from "swiper";
import { Grid } from "@mui/material";
//--elements--
import starElement from "../../../../Images/elements/star.svg";
import downArrow from "../../../../Images/elements/downArrow.svg";
import pentagon from "../../../../Images/elements/pentagon.svg";
// ---images--
import img1 from "../../../../Images/TestimonialesPage/img1.svg";
import bgImage from "../../../../Images/TestimonialesPage/BgLines.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";

import profileImage from "../../../../Assets/images/resources/avatar.webp";
import profileGabriella from "../../../../Assets/images/resources/gabriella.webp";
import profileIris from "../../../../Assets/images/resources/iris.webp";
import profileIrisSanchez from "../../../../Assets/images/resources/iris_sanchez.webp";

export default function BigCardDiv() {
	const cardsData = [
		{
			id: "testi1",
			name: "Gabriella",
			review: "Soy Gabriella, y trabajo con Ventadirekta desde junio 2021. Dichosas somos que podemos trabajar con una compañía que nos apoya tanto en el crecimiento de nuestro negocio, y la flexibilidad de trabajar desde la casa con nuestras familias. Gracias Ventadirekta.",
			img: profileGabriella,
			video: false,
		},
		{
			id: "testi2",
			name: "Iris Altagracia",
			review: "Mi nombre es Iris Altagracia, empecé a trabajar con Ventadirekta y estoy super agradecida por que perdí mi empleo por la pandemia y esta compañía me ha dado la solución para trabajar desde casa sin descuidar  a los míos, haciendo algo que me gusta que es las ventas y la belleza, además de eso, con mercancías de muy buena calidad, estoy super agradecida.",
			img: profileIris,
			video: false,
		},
		{
			id: "testi3",
			name: "Iris Sanchez",
			review: "Hola! Mi nombre es Iris Sanchez, soy del estado de Arizona... Tengo como 1 mes aproximadamente en Ventadirekta. Mi experiencia ha sido muy fascinante ya que tengo mucho tiempo libre para estar con mi familia, ya que tengo mi propia tienda virtual donde solo tengo que compartir con mis cielntas y listo! No tengo que preocuparme por el envío ni nada de eso, ya que la compañía se encarga de eso y es espectacular... Así que si estás intentando tener tu tienda virtual y vender las mejores marcas  de fajas en el mercado, te invito a que te unas a esta gran familia donde te brindan todo el apoyo y capacitación que necesitas.",
			img: profileIrisSanchez,
			video: false,
		},
	];

	return (
		<div className="section">
			{window.innerWidth > 910 ? (
				<div className="container BigCardDiv_dasktopSection">
					<div className="heading">
						<ReflectHeading boldText="TESTIMONIALES" />
					</div>
					<Grid container justifyContent={"center"} columnSpacing={2}>
						{/* -- */}
						{cardsData.map((e, i) => (
							<Grid item xs={3} className="card">
								<figure>
									<LazyLoadImage src={e.img} alt="#" />
								</figure>
								<div className="content">
									<div className="user">
										<LazyLoadImage src={e.img} alt={e.name} />
										<p>{e.name}</p>
									</div>
									<div className="review">" {e.review} "</div>
								</div>
							</Grid>
						))}
					</Grid>
					<div className="elementsDiv">
						<img src={starElement} alt="element" className="first" />
						<img src={downArrow} alt="element" className="second" />
						<img src={downArrow} alt="element" className="thired" />
						<img src={pentagon} alt="element" className="forth" />
					</div>

					<picture className="sectionBgImage">
						{/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
						<img src={bgImage} alt="Flowers" />
					</picture>
				</div>
			) : (
				<div className="container BigCardDiv_mobileSection">
					<div className="heading">
						<ReflectHeading boldText="TESTIMONIALES" />
					</div>

					<Swiper slidesPerView={1} spaceBetween={15} pagination={{ dynamicBullets: true }} modules={[Pagination]} className="cardSlider ">
						{cardsData.map((e, i) =>
							e.video ? (
								<SwiperSlide key={i}>
									<div className="sliderCard">
										<iframe height={650} src="https://www.youtube.com/embed/XdW3UvmgZgg" title="Testimonios Ventadirekta- Bella Peach Store" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
										<div className="content">
											<div className="user">
												<LazyLoadImage src={e.img} alt={e.name} />
												<p>{e.name}</p>
											</div>
											<div className="review">" {e.review} "</div>
										</div>
									</div>
								</SwiperSlide>
							) : (
								<SwiperSlide key={i}>
									<div className="sliderCard">
										<figure>
											<LazyLoadImage src={e.img} alt="#" className="mainImg" />
										</figure>
										<div className="content">
											<div className="user">
												<LazyLoadImage src={e.img} alt={e.name} />
												<p>{e.name}</p>
											</div>
											<div className="review">" {e.review} "</div>
										</div>
									</div>
								</SwiperSlide>
							)
						)}
					</Swiper>

					<div className="elementsDiv">
						<img src={starElement} alt="element" className="first" />
						<img src={downArrow} alt="element" className="second" />
					</div>
				</div>
			)}
		</div>
	);
}
