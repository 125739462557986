import React from 'react'
import { motion } from 'framer-motion'
import useAnimations from '../../../Auth/useAnimations'
import BlogSection from './BlogSection'
// --
export default function Blogs() {
    const { pageVariants } = useAnimations()
    return (
        <motion.div className='BlogsPage' variants={pageVariants} initial="initial" animate="visible">
            <BlogSection />
        </motion.div>
    )
}
