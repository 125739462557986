import React from "react";
import { BrowserRouter } from "react-router-dom";

import StoresRouter from "./Routes/Main";
import { LanguageContextProvider } from "./Context/Language";

function App() {
	return (
		<LanguageContextProvider>
			<BrowserRouter>
				<StoresRouter></StoresRouter>
			</BrowserRouter>
		</LanguageContextProvider>
	);
}

export default App;
