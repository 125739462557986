import { Typography } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion'
import useAnimations from '../../../Auth/useAnimations'

function Text({ text, classText, boldText }) {
    const { textAnimation } = useAnimations()
    return (
        <motion.div variants={textAnimation} initial="offscreen" whileInView="onscreen">
            <Typography variant='subtitle2' component="p" className={classText} sx={{ fontSize: "1rem", color: "var(--black-color)" }}>
                <b>{boldText}</b> {text}
            </Typography>
        </motion.div>
    )
}

export default Text