import React, { useState, useContext, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import logo from "../../../Images/siteLogo.png";
import { CgMenuRightAlt } from 'react-icons/cg'
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineYoutube } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5'

// --bg img--
import bgImage from '../../../Images/headerbgLines.png'
import bgImageMobile from '../../../Images/headerbgLinesMobile.svg'
//--elements--
import starElement from '../../../Images/elements/star.svg'
import downArrow from '../../../Images/elements/downArrow.svg'
import pentagon from '../../../Images/elements/pentagon.svg'

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DensityMediumIcon from "@mui/icons-material/DensityMedium";

import { LanguageContext } from "../../../Context/Language";

function Header(props) {
	const { languageData, setLanguage } = useContext(LanguageContext);
	// let currentLanguage = JSON.parse(languageData.languages).find((item) => item.code === languageData.current_language);

	const navigate = useNavigate()
    const [headerActvie, setHeaderActive] = useState("")
    const [menuShowHide, setmenuShowHide] = useState(false)

    window.addEventListener("scroll", () => {
        if (window.scrollY > 0) {
            setHeaderActive("active ")
        } else {
            setHeaderActive("")
        }
    })

    // ---menu show/hide fun---
    const menuShowHideFun = () => {
        setmenuShowHide(!menuShowHide)
    }
    useEffect(() => {

        if (menuShowHide == true) {
            document.getElementById('root').style.height = "100vh"
            document.getElementById('root').style.overflow = "hidden"
        } else {
            document.getElementById('root').style.height = "auto"
            document.getElementById('root').style.overflow = "auto"
        }
    }, [menuShowHide])

	return (
		<header className={`${headerActvie}`}>
			<div className="container">
				<div className="header">
					<div className="siteLogo">
						<img
							src={logo}
							alt="site logo"
							onClick={() => {
								navigate("/");
							}}
						/>
					</div>
					<div className="siteButtons">
						{/* <div className="languageChange">
							<Button id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
								<img src={`https://admin.ventadirekta.com${currentLanguage.flag}`} alt="" width={30} height={30} />
							</Button>
						</div> */}
						<Button
							onClick={menuShowHideFun}>
							<DensityMediumIcon style={{ color: "#000" }} />
						</Button>
					</div>
					<div className={`menuWrapper ${menuShowHide && "showMenu"}`}>
                        <div className={`menu`}>
                            <nav className='nav'>
                                <ul className='navItems'>
                                    <li className='navItem'> <NavLink to="/" onClick={menuShowHideFun}> <span>01</span>Inicio </NavLink></li>
                                    <li className='navItem'> <NavLink to="https://vdk-oficial.ventadirekta.com/" target="_blank" onClick={menuShowHideFun}> <span>02</span>Quiero <b>Comprar</b></NavLink></li>
                                    <li className='navItem'> <NavLink to="/about" onClick={menuShowHideFun}> <span>03</span>Nosotros </NavLink></li>
                                    <li className='navItem'> <NavLink to="/blogs" onClick={menuShowHideFun} > <span>04</span>BLOG </NavLink></li>
                                    <li className='navItem'> <NavLink to="/want-to-sell" onClick={menuShowHideFun}> <span>05</span>Quiero <b>Vender</b></NavLink></li>
                                    <li className='navItem'> <NavLink to="/contact" onClick={menuShowHideFun}> <span>06</span>Contáctanos</NavLink></li>
                                </ul>
                            </nav>
                            <div className="socialLinks">
                                <p className="heading">síguenos</p>
                                <a href="https://instagram.com/ventadirekta?utm_medium=copy_link" target="_blank" rel="noreferrer" className="link"><AiOutlineInstagram /> Instagram</a>
                                <a href="https://www.facebook.com/ventadirekta" target="_blank" rel="noreferrer" className="link"><AiOutlineFacebook /> Facebook</a>
                                <a href="https://www.youtube.com/channel/UC0DOLk_XTshdKkIBWTUksqg" target="_blank" rel="noreferrer" className="link"><AiOutlineYoutube /> Youtube</a>
                            </div>
                            <img src={logo} alt="logo" className='logo' />
                            <button className="closeBtn" onClick={menuShowHideFun}><IoClose /></button>
                            <div className="elementsDiv">
                                <img src={downArrow} alt="#" className='first' />
                                <img src={starElement} alt="#" className='second' />
                                <img src={pentagon} alt="#" className='thired' />
                            </div>

                            {/* --background image-- */}
                            <picture className='sectionBgImage'>
                                <source media="(max-width:575px)" srcSet={bgImageMobile} />
                                <img src={bgImage} alt="Flowers" />
                            </picture>
                        </div>
                    </div>
				</div>
			</div>
		</header>
	);
}

export default Header;
