import React from 'react'
import { motion } from 'framer-motion'
import useAnimations from '../../../Auth/useAnimations'
import AcademySection from './AcademySection'

// --
export default function Academy() {
    const { pageVariants } = useAnimations()
    return (
        <motion.div className='AcademyPage' variants={pageVariants} initial="initial" animate="visible">
            <AcademySection />
        </motion.div>
    )
}
