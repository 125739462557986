import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import MainButton from '../../../CommonComponent/Button/MainButton'
import EastIcon from '@mui/icons-material/East';
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import useAnimations from '../../../../Auth/useAnimations'
// --images--
import img1 from '../../../../Images/WantToSellPage/ForthSectionGalleryImg1.webp'
import img2 from '../../../../Images/WantToSellPage/ForthSectionGalleryImg2.webp'
import img3 from '../../../../Images/WantToSellPage/ForthSectionGalleryImg3.webp'
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'


// --bg lines--
import linesBg from '../../../../Images/WantToSellPage/ForthSectionBgLines.svg'

export default function ForthSection() {
    const navigate = useNavigate()
    const { textAnimation } = useAnimations()
    return (
        <div className="section forthSection">
            <div className="container forthContainer">
                <div className="boxes">
                    <div className="box ImgGallary">
                        <div className="images">
                            <div className="img1">
                                <LazyLoadImage src={img1} effect='blur' />
                            </div>
                            <div className="img2">
                                <LazyLoadImage src={img2} effect='blur' />
                            </div>

                            <div className="img3">
                                <LazyLoadImage src={img3} effect='blur' />
                            </div>

                        </div>
                    </div>
                    <div className="box content">
                        <div className="items">

                            <motion.h2 variants={textAnimation} initial="offscreen" whileInView="onscreen" className='heading'><span>¿ESTAS LISTO? </span>CREA YA TU TIENDA</motion.h2>
                            <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="pera">Comienza a ganar dinero extra con Ventadirekta.  Vende
                                productos colombianos sin preocuparte por nada.
                                Nosotros nos encargamos de crear tu sitio web,
                                facturación, despachos, logística e incluso servicio al
                                cliente.</motion.p>
                            <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="subHeading">¿Qué esperas? </motion.p>
                            <MainButton text="Registrate ya" action={() => navigate('/form')} startIcon={<EastIcon />} />
                        </div>
                    </div>
                </div>
                {/* --- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                    <img src={pentagon} alt="element" className="thired" />
                </div>
                {/* --background image-- */}

                <picture className='sectionBgImage'>
                    {/* <source media="(max-width:575px)" srcSet={mobile_bgImage} /> */}
                    <img src={linesBg} alt="bgLine" />
                </picture>

            </div>
        </div>
    )
}
