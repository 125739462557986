import React from 'react'
import MainButton from '../../../CommonComponent/Button/MainButton'
import { motion } from 'framer-motion'
import useAnimations from '../../../../Auth/useAnimations'
import { useNavigate } from 'react-router-dom';
// --icons--
import Profits from '../../../../Images/WantToSellPage/FifthSectionIcons/Profits.svg'
import Referrals from '../../../../Images/WantToSellPage/FifthSectionIcons/Referrals.svg'
import time from '../../../../Images/WantToSellPage/FifthSectionIcons/time.svg'
import customize from '../../../../Images/WantToSellPage/FifthSectionIcons/customize.svg'
import sales from '../../../../Images/WantToSellPage/FifthSectionIcons/sales.svg'
import platform from '../../../../Images/WantToSellPage/FifthSectionIcons/platform.svg'
import EastIcon from '@mui/icons-material/East';
//--elements--
import starElement from '../../../../Images/elements/star.svg'
import downArrow from '../../../../Images/elements/downArrow.svg'
import pentagon from '../../../../Images/elements/pentagon.svg'
import blockes from '../../../../Images/elements/blockes.svg'

export default function FifthSection() {
    const navigate = useNavigate()
    const { textAnimation } = useAnimations()
    return (
        <div className="section fifthSection">
            <div className="container fifthContainer">
                <div className="boxes">
                    <motion.p variants={textAnimation} initial="offscreen" whileInView="onscreen" className="heading">
                        <span> Vender por catalogo </span>
                        en usa en tu oportunidad
                    </motion.p>
                    {/* -- */}
                    <div className="cards">
                        <div className="card">
                            <div className="icon">
                                <img src={Profits} alt="profit" />
                                <p>ganancias</p>
                            </div>
                            <p className="pera">
                                USA genera millones de
                                ganancias al año en venta directa.
                            </p>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img src={Referrals} alt="ref" />
                                <p>Referidos</p>
                            </div>
                            <p className="pera">
                                Gana dinero adicional por
                                tus referidos directos que
                                formen parte de Ventadirekta
                            </p>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img src={time} alt="time" />
                                <p>tiempo</p>
                            </div>
                            <p className="pera">
                                Distribuye tu tiempo como
                                deseas
                            </p>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img src={customize} alt="custom" />
                                <p>personaliza</p>
                            </div>
                            <p className="pera">
                                Escoge a tu gusto el nombre
                                de tu propia tienda
                            </p>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img src={sales} alt="sale" />
                                <p>ventas</p>
                            </div>
                            <p className="pera">
                                Sin inversión de dinero. Solo
                                encárgate de las ventas
                            </p>
                        </div>
                        <div className="card">
                            <div className="icon">
                                <img src={platform} alt="platform" />
                                <p>plataforma</p>
                            </div>
                            <p className="pera">
                                Nosotros ponemos la plataforma, los productos
                                y la distribución
                            </p>
                        </div>
                    </div>
                    {/* -- */}
                    <div className="button">

                        <MainButton text="Quiero empezar" action={() => navigate('/form')} startIcon={<EastIcon />} />
                    </div>
                </div>

                {/* --- */}
                <div className="elementsDiv">
                    <img src={starElement} alt="element" className="first" />
                    <img src={downArrow} alt="element" className="second" />
                </div>
            </div>
        </div>
    )
}
